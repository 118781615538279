import { useTranslation } from "react-i18next";
import { Checkbox } from "shared/ui/Checkbox";
import { ListOfTypes } from "shared/ui/ListOfTypes/ui/ListOfTypes";
import styles from "../CarFilters.module.css";
import { types } from "features/СarFilters/model/constants";
import { CarFilterPart } from "features/СarFilters/model/interfaces";

const CarTypeAndCheckboxes = ({ filterData, handlers }: CarFilterPart) => {
  const { t } = useTranslation();
  return (
    <div className={styles.row}>
      <div className={styles.rowElement}>
        <ListOfTypes
          values={Object.keys(types)}
          selected={filterData.data.carType}
          onSelect={handlers.handleTypeChange}
        />
      </div>
      <div className={styles.rowElement}>
        <Checkbox
          onChange={(e) =>
            handlers.handleCheckboxChange(e.target.checked, "isAccidentFree")
          }
          checked={filterData.data.isAccidentFree}
          label={t("withNDS")}
          id="nds"
        />
        <Checkbox
          onChange={(e) =>
            handlers.handleCheckboxChange(e.target.checked, "isBelowMarket")
          }
          checked={filterData.data.isBelowMarket}
          label={t("underMarket")}
          id="under"
        />
        <Checkbox
          onChange={(e) =>
            handlers.handleCheckboxChange(e.target.checked, "isVat")
          }
          checked={filterData.data.isVat}
          label={t("noCrush")}
          id="crash"
        />
      </div>
    </div>
  );
};

export default CarTypeAndCheckboxes;
