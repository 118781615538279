import React, { useState, useEffect } from "react";
import styles from "./RangeInput.module.css";
import { useTranslation } from "react-i18next";

interface RangeInputProps {
  placeholders: string[];
  label?: string;
  fromValue?: number | string;
  toValue?: number | string;
  onFromChange?: (value: number | undefined) => void;
  onToChange?: (value: number | undefined) => void;
}

export function RangeInput({
  placeholders,
  label,
  fromValue: externalFromValue = "",
  toValue: externalToValue = "",
  onFromChange = () => {},
  onToChange = () => {},
}: RangeInputProps) {
  const { t } = useTranslation();
  const [fromValue, setFromValue] = useState<number | string | undefined>(
    externalFromValue
  );
  const [toValue, setToValue] = useState<number | string | undefined>(
    externalToValue
  );
  const [isFocused, setIsFocused] = useState({ left: false, right: false });
  const [error, setError] = useState(false);

  // Синхронизация с внешними значениями
  useEffect(() => {
    if (externalFromValue !== fromValue) {
      setFromValue(externalFromValue);
    }
  }, [externalFromValue]);

  useEffect(() => {
    if (externalToValue !== toValue) {
      setToValue(externalToValue);
    }
  }, [externalToValue]);

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === "") {
      setFromValue("");
      onFromChange(undefined);
      setError(false);
      return;
    }

    const value = Number(inputValue);
    if (isNaN(value)) {
      return;
    }

    if (toValue === "" || toValue === undefined || value <= Number(toValue)) {
      setFromValue(value);
      onFromChange(value);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue === "") {
      setToValue("");
      onToChange(undefined);
      setError(false);
      return;
    }

    const value = Number(inputValue);
    if (isNaN(value)) {
      return;
    }

    setToValue(value);
    onToChange(value);

    if (
      fromValue !== "" &&
      fromValue !== undefined &&
      value < Number(fromValue)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={`${styles.container} ${
          isFocused.left || isFocused.right ? styles.focused : ""
        }`}
      >
        <input
          type="number"
          onChange={handleFromChange}
          onFocus={() => setIsFocused((prev) => ({ ...prev, left: true }))}
          onBlur={() => setIsFocused((prev) => ({ ...prev, left: false }))}
          className={`${styles.inputLeft} ${error ? styles.inputError : ""}`}
          placeholder={placeholders[0]}
          value={fromValue === 0 ? "" : fromValue}
        />
        <input
          type="number"
          onChange={handleToChange}
          onFocus={() => setIsFocused((prev) => ({ ...prev, right: true }))}
          onBlur={() => setIsFocused((prev) => ({ ...prev, right: false }))}
          className={`${styles.inputRight} ${error ? styles.inputError : ""}`}
          placeholder={placeholders[1]}
          value={toValue === 0 ? "" : toValue}
        />
      </div>
    </div>
  );
}
