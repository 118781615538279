import useAddressStore, { AddressDataType } from "store/useAdressStore";
import styles from "../CarFilters.module.css";
import {
  countryCodeToName,
  lastAdded,
  nameToCountryCode,
  radius,
  sellers,
  users,
} from "features/СarFilters/model/constants";
import { MultiSelect } from "shared/ui/MultiSelect";
import { useTranslation } from "react-i18next";
import { Selector } from "shared/ui/Selector";
import { RangeInput } from "shared/ui/RangeInput";
import { CarFilterPart } from "features/СarFilters/model/interfaces";

const SecondaryFilters = ({ filterData, handlers }: CarFilterPart) => {
  const { t } = useTranslation();
  const { addresses } = useAddressStore();
  function getCountryNames(data: AddressDataType | null): string[] {
    if (!data) {
      return [];
    }
    return Object.keys(data).map(
      (code) => countryCodeToName[code] || "Unknown Country"
    );
  }

  function getCitiesBySelectedCountries(
    data: AddressDataType | null,
    selectedCountries: string[] | undefined
  ): string[] {
    if (!data || !selectedCountries || selectedCountries.length === 0) {
      return [];
    }

    const allCities = new Set<string>();

    for (const country of selectedCountries) {
      const countryCode = nameToCountryCode[country];
      const citiesOrRegions = data[countryCode];

      if (citiesOrRegions) {
        Object.keys(citiesOrRegions).forEach((city) => allCities.add(city));
      }
    }

    return Array.from(allCities);
  }

  return (
    <div className={styles.secondaryFilterContainer}>
      {/* Regions */}
      <div className={styles.rangeRowElementRegions}>
        <MultiSelect
          values={getCountryNames(addresses)}
          selected={filterData.data.regions}
          onSelected={handlers.handleRegionsChange}
          label={t("regions")}
        />
      </div>

      {/* Radius and Last Added */}
      <div className={styles.row}>
        <div className={styles.rowElement}>
          <Selector
            handleClear={handlers.handleRadiusClear}
            values={radius}
            value={filterData.data.radius?.toString() || ""}
            onValue={(value) => handlers.handleRadiusChange(parseInt(value))}
            label={t("radius")}
          />
        </div>
        <div className={styles.rowElement}>
          <Selector
            titles={Object.keys(lastAdded).map((item) => item.toString())}
            handleClear={handlers.handleLastAddClear}
            values={Object.values(lastAdded).map((item) => item.toString())}
            value={filterData.data.lastAdd?.toString() || ""}
            onValue={(value) => handlers.handleLastAddChange(parseInt(value))}
            label={t("lastAdded")}
          />
        </div>
      </div>

      {/* Price Range */}
      <div className={styles.rangeRowElement}>
        <RangeInput
          placeholders={[t("priceFrom"), t("upTo")]}
          fromValue={filterData.data.priceFrom}
          toValue={filterData.data.priceTo}
          onFromChange={(value) =>
            handlers.handleRangeChange("priceFrom", value)
          }
          onToChange={(value) => handlers.handleRangeChange("priceTo", value)}
        />
      </div>

      {/* Cities */}
      <div className={styles.rangeRowElement}>
        <MultiSelect
          values={getCitiesBySelectedCountries(
            addresses,
            filterData.data.regions
          )}
          selected={filterData.data.cities || []}
          onSelected={handlers.handleCitiesChange}
          label={t("citiesZip")}
          disabled={
            !filterData.data.regions || filterData.data.regions.length === 0
          }
        />
      </div>
      {/* Seller Type and Owners */}
      <div className={styles.row}>
        <div className={styles.rowElement}>
          <Selector
            handleClear={handlers.handleSellerTypeClear}
            values={Object.values(sellers)}
            value={filterData.data.typeOfSeller}
            onValue={handlers.handleSellerTypeChange}
            label={t("sellerType")}
          />
        </div>
        <div className={styles.rowElement}>
          <Selector
            handleClear={handlers.handleOwnersClear}
            values={Object.keys(users)}
            value={filterData.data.amountOfOwners?.toString() || ""}
            onValue={(value) => handlers.handleOwnersChange(parseInt(value))}
            label={t("usersQuantity")}
          />
        </div>
      </div>
    </div>
  );
};

export default SecondaryFilters;
