import React, { createContext, useState, useEffect, ReactNode } from "react";
import { getMainPageData } from "../widgets/CarShowcase/lib/request";
import { MainPageResponse, MainPageContextProps } from "../common/interfaces";
import { useTranslation } from "react-i18next";
import { useCache } from "shared/hooks/useCache";

const defaultContext: MainPageContextProps = {
  mainPageData: null,
  loading: true,
  error: null,
};

// Создаем контекст
export const MainPageContext =
  createContext<MainPageContextProps>(defaultContext);

// Типизируем пропсы провайдера
interface MainPageProviderProps {
  children: ReactNode;
}

const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // 1 час в миллисекундах

export const MainPageProvider: React.FC<MainPageProviderProps> = ({
  children,
}) => {
  const [mainPageData, setMainPageData] = useState<MainPageResponse | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const { getCache, setCache } = useCache(
    "mainPageData",
    CACHE_EXPIRATION_TIME
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, valid } = getCache();
        if (valid && data) {
          setMainPageData(data);
          return;
        }

        setLoading(true);
        const freshData: any = await getMainPageData();

        if (!freshData) throw new Error(t("mainPageDataNoGet"));
        if (freshData.detail) {
          const newFreshData: any = await getMainPageData();
          setMainPageData(newFreshData);
          setCache(newFreshData);
        } else {
          setMainPageData(freshData);
          setCache(freshData);
        }
      } catch (err) {
        setError(t("errorOnDataLoading"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [t]);

  return (
    <MainPageContext.Provider value={{ mainPageData, loading, error }}>
      {children}
    </MainPageContext.Provider>
  );
};
