import { Input } from "shared/ui/input";
import { Button } from "shared/ui/button";
import styles from "./ChangeUserLoginForm.module.css";
import { useState, useEffect } from "react";
import { sendPostRequest, sendPutRequest } from "shared/api/lib/requests";
import { useTranslation } from "react-i18next";
import { PATH_API } from "shared/config";

export function ChangeUserLoginInfoForm() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [timer, setTimer] = useState(60);
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getUserData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("Access token not found");
        return;
      }

      const response = await fetch(
        `${PATH_API}/v1/search/user-info?access_token=${accessToken}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      const data = await response.json();
      if (data) {
        setEmail(data.email || "");
        setPhone(data.phone || "");
        setName(data.name || "");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const changeName = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        showTemporaryMessage(t("accessTokenNotFound"), setErrorMessage);
        return;
      }

      const response: any = await sendPutRequest("search/user-info", {
        access_token: accessToken,
        name: name,
      });
      if (response.message === "User info successfully updated") {
        showTemporaryMessage(t("nameChangedSuccessfully"), setSuccessMessage);
      } else {
        showTemporaryMessage(t("errorChangingName"), setErrorMessage);
      }
    } catch (error) {
      showTemporaryMessage(t("errorChangingName"), setErrorMessage);
      console.error("Error changing name:", error);
    }
  };

  async function sendChangedData() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        showTemporaryMessage(t("accessTokenNotFound"), setErrorMessage);
        return;
      }

      await sendPostRequest("search/add-email", {
        email: email,
        access_token: accessToken,
      });

      setIsModalOpen(true);
      startTimer();
      showTemporaryMessage(t("emailVerificationSent"), setSuccessMessage);
    } catch (error) {
      showTemporaryMessage(t("errorSendingEmail"), setErrorMessage);
      console.error("Error sending email:", error);
    }
  }

  async function sendCode() {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        showTemporaryMessage(t("accessTokenNotFound"), setErrorMessage);
        return;
      }

      const fullCode = verificationCode.join("");
      await sendPostRequest("search/confirm-email", {
        access_token: accessToken,
        verify_code: fullCode,
      });

      setIsModalOpen(false);
      showTemporaryMessage(t("emailChangedSuccessfully"), setSuccessMessage);
    } catch (error) {
      showTemporaryMessage(t("errorConfirmingEmail"), setErrorMessage);
      console.error("Error confirming email:", error);
    }
  }

  function startTimer() {
    setTimer(60);
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }

  function handleCodeChange(index: number, value: string) {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      // Auto-focus next input
      if (value !== "" && index < 3) {
        const nextInput = document.getElementById(`code-${index + 1}`);
        nextInput?.focus();
      }
    }
  }

  // Обновленная функция показа сообщений
  const showTemporaryMessage = (
    message: string,
    setMessage: (msg: string | null) => void
  ) => {
    setMessage(message);
    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };

  return (
    <div>
      {successMessage && (
        <div className={styles.successMessage} role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className={styles.errorMessage} role="alert">
          {errorMessage}
        </div>
      )}

      <div className={styles.content}>
        <p className={styles.sectionName}>{t("account")}</p>
        <Input
          placeholder={t("personName")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Button onClick={changeName}>{t("changeName")}</Button>
        <p className={styles.text}>{t("enterData")}</p>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("email")}
        />
        <Input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder={t("phoneNumber")}
        />
        <Button onClick={sendChangedData}>{t("changeEmail")}</Button>
      </div>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>{t("enterSmsCode")}</h2>
            <p>{t("smsCodeSent")} +7 999 999 99 99</p>

            <div className={styles.codeInputs}>
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  id={`code-${index}`}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  className={styles.codeInput}
                />
              ))}
            </div>

            <p className={styles.timer}>
              {timer > 0 ? (
                t("resendCodeIn", { seconds: timer })
              ) : (
                <button onClick={startTimer} className={styles.resendButton}>
                  {t("resendCode")}
                </button>
              )}
            </p>

            <div className={styles.modalButtons}>
              <Button onClick={() => setIsModalOpen(false)}>
                {t("cancel")}
              </Button>
              <Button
                onClick={sendCode}
                disabled={verificationCode.join("").length !== 4}
              >
                {t("confirm")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
