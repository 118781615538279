import { MultiSelect } from "shared/ui/MultiSelect";
import styles from "../CarFilters.module.css";
import {
  bodyTypes,
  driveTypes,
  fuelTypes,
  transmissions,
} from "features/СarFilters/model/constants";
import { useTranslation } from "react-i18next";
import { Selector } from "shared/ui/Selector";
import { RangeInput } from "shared/ui/RangeInput";
import { CarFilterPart } from "features/СarFilters/model/interfaces";
const MainFilters = ({ filterData, handlers }: CarFilterPart) => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainFilterContainer}>
      <div className={styles.row}>
        <div className={styles.rowElement}>
          <MultiSelect
            values={Object.keys(bodyTypes)}
            selected={filterData.data.carBody}
            onSelected={handlers.handleBodyTypeChange}
            label={t("body")}
          />
        </div>
        <div className={styles.rowElement}>
          <MultiSelect
            values={Object.keys(transmissions)}
            selected={filterData.data.transmission}
            onSelected={handlers.handleTransmissionChange}
            label={t("box")}
          />
        </div>
      </div>
      {/* Fuel and Drive Types */}
      <div className={styles.row}>
        <div className={styles.rowElement}>
          <Selector
            handleClear={handlers.handleFuelTypeClear}
            values={Object.values(fuelTypes)}
            value={filterData.data.fuelTypes}
            onValue={handlers.handleFuelTypeChange}
            label={t("engine")}
          />
        </div>
        <div className={styles.rowElement}>
          <Selector
            handleClear={handlers.handleDriveTypeClear}
            values={Object.values(driveTypes)}
            value={filterData.data.driveTypes}
            onValue={handlers.handleDriveTypeChange}
            label={t("drive")}
          />
        </div>
      </div>
      {/* Volume Range */}
      <div className={styles.rangeRowElement}>
        <RangeInput
          placeholders={[t("volumeLiters"), t("upTo")]}
          fromValue={filterData.data.volumeFrom}
          toValue={filterData.data.volumeTo}
          onFromChange={(value) =>
            handlers.handleRangeChange("volumeFrom", value)
          }
          onToChange={(value) => handlers.handleRangeChange("volumeTo", value)}
        />
      </div>
      {/* Year Range */}
      <div className={styles.rangeRowElement}>
        <RangeInput
          placeholders={[t("yearFrom"), t("upTo")]}
          fromValue={filterData.data.yearFrom}
          toValue={filterData.data.yearTo}
          onFromChange={(value) =>
            handlers.handleRangeChange("yearFrom", value)
          }
          onToChange={(value) => handlers.handleRangeChange("yearTo", value)}
        />
      </div>
      {/* Mileage Range */}
      <div className={styles.rangeRowElement}>
        <RangeInput
          placeholders={[t("mileageKmFrom"), t("upTo")]}
          fromValue={filterData.data.mileageFrom}
          toValue={filterData.data.mileageTo}
          onFromChange={(value) =>
            handlers.handleRangeChange("mileageFrom", value)
          }
          onToChange={(value) => handlers.handleRangeChange("mileageTo", value)}
        />
      </div>
      <div className={styles.rangeRowElement}>
        <RangeInput
          placeholders={[t("power"), t("upTo")]}
          fromValue={filterData.data.powerFrom}
          toValue={filterData.data.powerTo}
          onFromChange={(value) =>
            handlers.handleRangeChange("powerFrom", value)
          }
          onToChange={(value) => handlers.handleRangeChange("powerTo", value)}
        />
      </div>
    </div>
  );
};

export default MainFilters;
