import { useTranslation } from "react-i18next";
import styles from "./ApiPage.module.css";
import { Helmet } from "react-helmet-async";

export const ApiPage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.layout}>
      <Helmet>
        <title>CarPass | {t("api")}</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header_info}>
            <h1>{t("api")}</h1>
          </div>
          <div className={styles.header_info}>{t("apiNotReady")}</div>
        </div>
      </div>
    </div>
  );
};
