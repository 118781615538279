import { useTranslation } from "react-i18next";
import { CarsList } from "widgets/CarsList/ui/CarsList";
import styles from "./FavouritesPage.module.css";
import { useFavoriteContext } from "contexts/FavoriteContext";
import { useState, useEffect } from "react";
import { SortField } from "features/СarFilters/model/interfaces";
import { Helmet } from "react-helmet-async";

export function FavouritesPage() {
  const { t } = useTranslation();
  const { favoriteList } = useFavoriteContext();
  const [loading, setLoading] = useState(true);
  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price_eur",
    order: "asc",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleSort = (field: SortField["field"], order: SortField["order"]) => {
    setCurrentSort({ field, order });
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>CarPass | {t("favorite")}</title>
        <meta
          name="description"
          content="Ваши избранные автомобили на CarPass. Сохраняйте интересные предложения и отслеживайте изменения цен на выбранные автомобили."
        />
        <meta
          name="keywords"
          content="избранные автомобили, сохраненные авто, отслеживание цен, CarPass избранное"
        />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="CarPass - Избранные автомобили" />
        <meta
          property="og:description"
          content="Ваши избранные автомобили на CarPass. Сохраняйте интересные предложения и отслеживайте изменения цен на выбранные автомобили."
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CarPass - Избранные автомобили" />
        <meta
          name="twitter:description"
          content="Ваши избранные автомобили на CarPass. Сохраняйте интересные предложения и отслеживайте изменения цен на выбранные автомобили."
        />

        {/* Additional SEO tags */}
        <meta name="robots" content="noindex, follow" />
        <link rel="canonical" href="https://carpass.com/favourites" />
      </Helmet>
      <h1 className={styles.title}>{t("favorite")}</h1>
      <CarsList
        cars={favoriteList}
        onSort={handleSort}
        currentSort={currentSort}
        loading={loading}
      />
    </div>
  );
}
