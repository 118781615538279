import styles from "./Footer.module.css";

export function Footer() {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.left}>
                    <span>2024-2025 <strong>©carpass.com</strong></span>
                </div>
            
                
                <div className={styles.right}>
                    <a href="https://generext.com" target="_blank" rel="noopener noreferrer">
                        <strong>Dev by Generext 💜</strong>
                    </a>
                </div>
            </div>
        </>
    );
}