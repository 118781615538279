import { useLocation } from "react-router-dom";
import styles from "./CarDetails.module.css"; // Стили для кастомной сцены
import { useTranslation } from "react-i18next";

export function CarDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const car = location.state?.car; // Получаем объект car из переданного состояния

  if (!car) {
    return <div>{t("carNotFound")}</div>;
  }

  return (
    <div className={styles.carDetailsContainer}>
      <h1>
        {car.mark} {car.model}
      </h1>
      <img
        src={car.image_urls ? car.image_urls[0] : "defaultImage.jpg"}
        alt={car.model}
        className={styles.carImage}
      />
      <div className={styles.carInfo}>
        <p>
          {t("price_eur")}: {car.price_eur} €
        </p>
        <p>
          {t("year")}: {car.year}
        </p>
        <p>
          {t("mileage")}:{" "}
          {car.km_age === 0 ? t("newOne") : `${car.km_age} ${t("km")}`}
        </p>
      </div>
    </div>
  );
}
