import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LangSwitcher.module.css";
import ArrowDownIcon from "../assets/ArrowDownIcon";
import { capitalizeFirstLetter } from "common/utils";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // State to track dropdown visibility
  const [currentLang, setCurrentLang] = useState<string>(""); // Track the current selected language

  // 🇷🇺
  // 🇺🇸
  // 🇩🇪
  // 🇬🇪

  const langs = [
    { name: "ru", icon: "🇷🇺" },
    { name: "en", icon: "🇺🇸" },
    { name: "de", icon: "🇩🇪" },
    { name: "ka", icon: "🇬🇪" },
  ];

  // Set the initial language from localStorage or default to "Ru"
  useEffect(() => {
    const storedLang = localStorage.getItem("i18nextLng"); // Retrieve from i18nextLng key
    if (storedLang?.length === 5) {
      changeLanguage(storedLang.slice(0, 2));
    } else {
      changeLanguage(storedLang || "ru");
    }
  }, [i18n]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurrentLang(lng); // Update the current language state
    localStorage.setItem("i18nextLng", lng); // Save language to i18nextLng key
    setIsOpen(false); // Close dropdown after language change
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle the dropdown visibility
  };

  return (
    <div className={styles.languageSwitcherContainer}>
      {/* Language switcher trigger */}
      <div className={styles.locale} onClick={toggleDropdown}>
        {/* Display current selected language */}
        {langs.find((lang) => lang.name === currentLang)?.icon}
        <p>{capitalizeFirstLetter(currentLang)}</p>
        <ArrowDownIcon />
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {langs.map((lang) => (
            <div
              key={lang.name}
              onClick={() => changeLanguage(lang.name)}
              className={styles.dropdownItem}
            >
              {lang.icon}
              <p>{capitalizeFirstLetter(lang.name)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
