import {
  sendGetRequest,
  sendPostRequest,
} from "../../../shared/api/lib/requests";
import {
  AvaliableCarsResponse,
  FilterData,
  CarsCountResponse,
} from "../model/interfaces";
import {
  AllCarsResponse,
  Cars,
  CarsResponse,
} from "../../../common/interfaces";

export const getCarsNames = async (): Promise<AvaliableCarsResponse> => {
  const res: AvaliableCarsResponse =
    await sendGetRequest<AvaliableCarsResponse>("search/available-cars");
  return res;
};

export const validateFilters = (filter: FilterData): Partial<FilterData> => {
  // Фильтрация параметров с пустыми строками, нулями или неопределёнными значениями
  const cleanedFilter = Object.fromEntries(
    Object.entries(filter).filter(([key, value]) => {
      if (typeof value === "number") {
        return value !== 0 && !isNaN(value); // Убираем числовые поля, если значение 0 или NaN
      }
      if (Array.isArray(value)) {
        return value.length > 0; // Убираем массивы, если они пустые
      }
      return value !== "" && value !== undefined && value !== null; // Убираем пустые строки, undefined и null
    })
  );

  return cleanedFilter;
};

export const getCarsCount = async (
  filter: FilterData
): Promise<{ count: number }> => {
  const cleanedFilter = removeEmptyFields(filter);

  const filterData =
    cleanedFilter.data.brand_details[0].brand === ""
      ? { ...cleanedFilter.data, brand_details: undefined }
      : cleanedFilter.data;
  const res: CarsCountResponse = await sendPostRequest<CarsCountResponse>(
    `cars-system/cars-count`,
    filterData
  );

  return res.data;
};

function removeEmptyFields(obj: any): any {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the key is 'brand_details'
      if (key === "brand_details") {
        // If 'brand_details' is an array, remove empty objects
        if (
          Array.isArray(obj[key]) &&
          obj[key].every((item: any) => Object.keys(item).length === 0)
        ) {
          delete obj[key]; // Remove the 'brand_details' field if it contains only empty objects
        }
      } else if (key === "isAccidentFree" && obj[key] === false) {
        delete obj[key];
      } else if (key === "isBelowMarket" && obj[key] === false) {
        delete obj[key];
      } else if (key === "isVat" && obj[key] === false) {
        delete obj[key];
      }
      // Remove keys if the value is empty, null, or an empty array
      else if (
        obj[key] === "" ||
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      }
      // If the value is an object, recursively call removeEmptyFields
      else if (typeof obj[key] === "object" && obj[key] !== null) {
        removeEmptyFields(obj[key]);
      }
    }
  }

  return obj;
}

export const getCars = async (filter: FilterData): Promise<Cars[]> => {
  try {
    const cleanedFilter = removeEmptyFields(filter);
    const filterData =
      cleanedFilter.data.brand_details[0].brand === ""
        ? { ...cleanedFilter.data, brand_details: undefined }
        : cleanedFilter.data;

    const sort_fields =
      cleanedFilter.sort_metrics.sort_fields[0].order === "none"
        ? []
        : cleanedFilter.sort_metrics.sort_fields;
    const final = {
      data: filterData,
      sort_fields,
    };

    const res: CarsResponse = await sendPostRequest<CarsResponse>(
      "search/search",
      final
    );
    return res.data.cars;
  } catch (error) {
    console.error("Error in getCars:", error);
    throw error;
  }
};

export interface Metrics {
  cars_count: number;
  deactivated_cars_last_3_months_count: number;
  average_selling_price: number;
  average_mileage_at_selling: number;
  average_time_on_market: number;
  liquidity: number;
}
interface MarketCarsDto {
  data: Metrics;
  cars: Cars[];
}

export const getMarketCars = async (
  filter: FilterData
): Promise<MarketCarsDto> => {
  const cleanedFilter: FilterData = removeEmptyFields(filter);
  const sort_fields =
    cleanedFilter.sort_metrics.sort_fields[0].order === "none"
      ? []
      : cleanedFilter.sort_metrics.sort_fields;
  const transformedData: any = {
    sort_fields,
    data: {
      ...cleanedFilter.data,
      brand: cleanedFilter.data.brand_details[0].brand,
      model: [cleanedFilter.data.brand_details[0].models[0] ?? ""],
      generation: cleanedFilter.data.brand_details[0].generation.length
        ? cleanedFilter.data.brand_details[0].generation
        : [0],
      custom_input: cleanedFilter.data.brand_details[0].custom_input,
      brand_details: undefined,
    },
  };

  const res: any = await sendPostRequest("exchange/search", transformedData);

  const carList: MarketCarsDto = res.data;

  return carList;
};

export const getALlCars = async (): Promise<Cars[]> => {
  // Валидация фильтров

  const res: AllCarsResponse = await sendGetRequest<AllCarsResponse>(
    "search/available-cars"
  );

  const carList: Cars[] = res.data.cars.cars;

  return carList;
};
