import { CarFilterPart } from "features/СarFilters/model/interfaces";
import styles from "../CarFilters.module.css";
import { Selector } from "shared/ui/Selector";
import { useTranslation } from "react-i18next";
import { useCarFiltersData } from "features/СarFilters/hooks/useCarFiltersData";
import { MultiSelect } from "shared/ui/MultiSelect";
import { Input } from "shared/ui/input";

interface IBrandDetails extends CarFilterPart {
  isMarket: boolean;
}

const BrandDetails = ({ filterData, handlers, isMarket }: IBrandDetails) => {
  const { t } = useTranslation();
  const { brandsList, getModelsByBrand } = useCarFiltersData(isMarket);
  return (
    <div className={styles.brandDetails}>
      {filterData.data.brand_details &&
      Array.isArray(filterData.data.brand_details) ? (
        filterData.data.brand_details.map((detail, index) => (
          <div key={index} className={styles.brandDetail}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 24,
                width: "100%",
              }}
            >
              <Selector
                label={t("mark")}
                values={brandsList}
                value={detail.brand}
                handleClear={handlers.clearBrandName}
                onValue={(value) => handlers.handleBrandChange(value, index)}
              />
              {!isMarket && index === 0 && (
                <button
                  onClick={handlers.addBrandDetail}
                  className={styles.addButton}
                >
                  +
                </button>
              )}
            </div>
            {isMarket ? (
              <Selector
                label={t("model")}
                values={getModelsByBrand(detail.brand)}
                value={detail.models[0]}
                disabled={!detail.brand}
                onValue={(value) => handlers.handleModelsChange([value], index)}
              />
            ) : (
              <MultiSelect
                label={t("model")}
                values={getModelsByBrand(detail.brand)}
                selected={detail.models}
                disabled={!detail.brand}
                onSelected={(values) =>
                  handlers.handleModelsChange(values, index)
                }
              />
            )}
            <MultiSelect
              label={t("generation")}
              values={[]}
              selected={detail.generation.map(String)}
              disabled
              onSelected={(values) =>
                handlers.handleGenerationsChange(values.map(Number), index)
              }
            />
            <Input
              placeholder={t("useGTI")}
              value={detail.custom_input}
              onChange={(e) =>
                handlers.handleCustomInputChange(e.target.value, index)
              }
            />
            {filterData.data.brand_details.length > 1 && index !== 0 && (
              <button
                onClick={() => handlers.removeBrandDetail(index)}
                className={styles.removeButton}
              >
                ×
              </button>
            )}
          </div>
        ))
      ) : (
        <div>No brand details available.</div>
      )}
    </div>
  );
};

export default BrandDetails;
