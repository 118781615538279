export const useCache = (key: string, expirationTime: number) => {
  const getCache = (): { data: any; valid: boolean } => {
    const cachedData = localStorage.getItem(key);
    const cacheTimestamp = Number(localStorage.getItem(`${key}Timestamp`));
    const now = Date.now();

    return {
      data: cachedData ? JSON.parse(cachedData) : null,
      valid: now - cacheTimestamp < expirationTime,
    };
  };

  const setCache = (data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
    localStorage.setItem(`${key}Timestamp`, Date.now().toString());
  };

  return { getCache, setCache };
};
