const users: Record<string, undefined | number> = {
  "": undefined,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
};

const lastAdded: Record<string, number> = {
  today: 1,
  onWeek: 7,
  onMonth: 30,
};

const bodyTypes: Record<string, string> = {
  boxtypedeliveryvan: "boxtypedeliveryvan",
  cabrio: "cabrio",
  clubbus: "clubbus",
  estatecar: "estatecar",
  estateminibusupto9seatsvan: "estateminibusupto9seatsvan",
  highandlongboxtypedeliveryvan: "highandlongboxtypedeliveryvan",
  highboxtypedeliveryvan: "highboxtypedeliveryvan",
  limousine: "limousine",
  longboxtypedeliveryvan: "longboxtypedeliveryvan",
  motorhomesandother: "motorhomesandother",
  offroad: "offroad",
  otherbus: "otherbus",
  othercar: "othercar",
  othervanupto7500: "othervanupto7500",
  smallcar: "smallcar",
  sportscar: "sportscar",
  stakebodyvan: "stakebodyvan",
  van: "van",
  vanmotorhome: "vanmotorhome",
};
const transmissions: Record<string, string> = {
  automatic: "automatic",
  manual: "manual",
  "semi-automatic": "semi-automatic",
  CVT: "CVT",
};

const fuelTypes: Record<string, string> = {
  Бензин: "petrol",
  Дизель: "diesel",
  Электричество: "electric",
  Гибрид: "hybrid",
  Водород: "hydrogen",
  "Природный газ": "natural gas",
};

const driveTypes: Record<string, string> = {
  "Передний привод": "front-wheel",
  "Задний привод": "rear-wheel",
  "Полный привод": "all-wheel",
  "Четырехколесный привод": "four-wheel",
};

const radius: string[] = ["10", "20", "30"];
const types: Record<string, string> = {
  all: "all",
  new: "new",
  withMileage: "withMileage",
};

export const marks = [
  "Volkswagen",
  "Opel",
  "Audi",
  "Other",
  "Ford",
  "BMW",
  "Mercedes-Benz",
  "Skoda",
  "Smart",
  "Renault",
  "Fiat",
  "Seat",
  "Nissan",
  "Hyundai",
  "Toyota",
  "Citroën",
  "Peugeot",
  "Kia",
  "Dacia",
  "Porsche",
  "MINI",
  "Mazda",
  "Jeep",
  "Cupra",
  "Volvo",
  "Lancia",
  "Honda",
  "Suzuki",
  "Mitsubishi",
  "Land Rover",
  "Alfa Romeo",
  "Tesla",
  "Chevrolet",
  "Subaru",
  "Jaguar",
  "Dodge",
  "Trabant",
  "Iveco",
  "Chrysler",
  "Abarth",
  "Saab",
  "Daihatsu",
  "Lada",
  "DS Automobiles",
  "Maserati",
  "MG",
  "Lamborghini",
  "Lexus",
  "Aixam",
  "Lynk&Co",
  "Isuzu",
  "Bentley",
  "Rover",
  "MAN",
  "Ssangyong",
  "Cadillac",
  "Polestar",
  "Ferrari",
  "Alpine",
  "Aston Martin",
  "Daewoo",
  "Ligier",
  "Hummer",
  "GWM",
  "Corvette",
  "Rolls-Royce",
  "SWM",
  "ALPINA",
  "Infiniti",
  "Piaggio",
  "Maxus",
  "BAIC",
  "Austin Healey",
  "DFSK",
  "INEOS",
  "GMC",
  "BYD",
  "Lotus",
  "McLaren",
  "AC",
  "Leapmotor",
  "Plymouth",
  "Pontiac",
  "Triumph",
  "Genesis",
  "Morgan",
  "ORA",
  "Wartburg",
  "Elaris",
  "Lincoln",
  "Microcar",
  "NSU",
  "Tata",
  "Borgward",
  "Aiways",
  "XPENG",
  "Maybach",
  "JAC",
  "Oldsmobile",
  "Seres",
  "Cobra",
  "Austin",
  "Chatenet",
  "e.GO",
  "DeTomaso",
  "Barkas",
  "Bugatti",
  "Buick",
  "XEV",
  "Fisker",
  "Donkervoort",
  "TECHART",
  "Westfalia",
  "Casalini",
  "NIO",
  "Westfield",
  "Grecav",
  "Lucid",
  "Microlino",
  "WEY",
  "TVR",
  "LEVC",
  "Wiesmann",
  "Brilliance",
  "Acura",
  "Talbot",
  "Zhidou",
  "Asia Motors",
].sort((a, b) => a.localeCompare(b));

export const models = {
  Volkswagen: [
    "Golf",
    "Polo",
    "Passat",
    "Touran",
    "Tiguan",
    "Other",
    "Passat Variant",
    "Caddy",
    "T-Roc",
    "T-Cross",
    "Golf Plus",
    "Touareg",
    "Sharan",
    "up!",
    "LT",
    "Taigo",
    "Crafter",
    "Lupo",
    "T5 Multivan",
    "Tiguan Allspace",
    "Fox",
    "T6 Multivan",
    "T5 Transporter",
    "T6 Transporter",
    "Arteon",
    "Caddy Maxi",
    "Scirocco",
    "T4 Multivan",
    "Golf Sportsvan",
    "Bora",
    "Beetle",
    "Amarok",
    "Eos",
    "Passat CC",
    "Jetta",
    "T7 Multivan",
    "ID.3",
    "T6 California",
    "New Beetle",
    "T5 Caravelle",
    "T4 Caravelle",
    "Phaeton",
    "Passat Alltrack",
    "CC",
    "ID.4",
    "T6 Caravelle",
    "T6 Kombi",
    "T2",
    "ID. Buzz",
    "T7 California",
    "Käfer",
    "T5 California",
    "Corrado",
    "T4 California",
    "T4 other",
    "ID.5",
    "T1",
    "ID.7",
    "Vento",
    "T3 Multivan",
    "T5 Kombi",
    "T3 other",
    "T5 other",
    "T4 Kombi",
    "T6 other",
    "T3 Caravelle",
    "Karmann Ghia",
    "T5 Shuttle",
    "181",
    "Iltis",
    "Buggy",
    "T3 Kombi",
    "Taro",
    "T7 Transporter",
    "Routan",
    "Tayron",
    "Santana",
    "T7 other",
    "XL1",
  ],
  Opel: [
    "Corsa",
    "Astra",
    "Insignia",
    "Zafira",
    "Meriva",
    "Mokka",
    "Grandland (X)",
    "Vectra",
    "Adam",
    "Vivaro",
    "Crossland (X)",
    "Combo",
    "Agila",
    "Mokka X",
    "Other",
    "Movano",
    "Tigra",
    "Antara",
    "Zafira Tourer",
    "Signum",
    "Omega",
    "Kadett",
    "Zafira Life",
    "Karl",
    "Mokka-e",
    "Cascada",
    "Combo Life",
    "Rekord",
    "Frontera",
    "Ascona",
    "Rocks-e",
    "Calibra",
    "GT",
    "Manta",
    "Senator",
    "Insignia CT",
    "Nova",
    "Ampera",
    "Commodore",
    "Speedster",
    "Campo",
    "Ampera-e",
    "Monterey",
    "Diplomat",
    "Sintra",
    "Monza",
    "Combo Electric",
    "Cavalier",
  ],
  Audi: [
    "A4",
    "A6",
    "A3",
    "A5",
    "Q5",
    "Q3",
    "A1",
    "TT",
    "Q7",
    "Q2",
    "A8",
    "80",
    "quattro",
    "SQ5",
    "A7",
    "S3",
    "Q8",
    "RS3",
    "A2",
    "S5",
    "A6 Allroad",
    "RS6",
    "A4 Allroad",
    "S6",
    "e-tron",
    "S4",
    "100",
    "SQ7",
    "Other",
    "RS4",
    "Q4",
    "Q4 e-tron",
    "RS5",
    "Cabriolet",
    "RSQ8",
    "R8",
    "RSQ3",
    "SQ8",
    "Q8 e-tron",
    "S8",
    "RS7",
    "e-tron GT",
    "S7",
    "90",
    "TTS",
    "TT RS",
    "Coupé",
    "200",
    "RS e-tron GT",
    "SQ2",
    "S1",
    "Q6 e-tron",
    "SQ8 e-tron",
    "RS2",
    "SQ6 e-tron",
    "S2",
    "V8",
    "A6 e-tron",
  ],
  Other: ["Other"],
  Ford: [
    "Focus",
    "Fiesta",
    "Kuga",
    "Mondeo",
    "Transit",
    "C-Max",
    "S-Max",
    "Puma",
    "Transit Custom",
    "Ka/Ka+",
    "Ranger",
    "Galaxy",
    "EcoSport",
    "Mustang",
    "Tourneo Custom",
    "Fusion",
    "Transit Connect",
    "B-Max",
    "Grand C-Max",
    "Tourneo Courier",
    "Tourneo Connect",
    "Edge",
    "Other",
    "Tourneo",
    "Transit Courier",
    "Explorer",
    "Escort",
    "F 150",
    "Grand Tourneo",
    "Streetka",
    "Taunus",
    "Sierra",
    "Mustang Mach-E",
    "Raptor",
    "Maverick",
    "Capri",
    "F 250",
    "Bronco",
    "Thunderbird",
    "Probe",
    "Mercury",
    "Granada",
    "Econoline",
    "Courier",
    "Cougar",
    "F 100",
    "Fairlane",
    "Scorpio",
    "Expedition",
    "GT",
    "F 350",
    "Crown",
    "Windstar",
    "Flex",
    "Excursion",
    "Escape",
    "Orion",
    "Taurus",
    "Falcon",
    "Sportka",
    "Express",
  ],
  BMW: [
    "320",
    "318",
    "Other",
    "520",
    "530",
    "118",
    "X1",
    "116",
    "X3",
    "X5",
    "330",
    "525",
    "316",
    "325",
    "120",
    "335",
    "535",
    "523",
    "X6",
    "730",
    "X4",
    "X2",
    "218",
    "420",
    "540",
    "M3",
    "328",
    "740",
    "M4",
    "220",
    "Z4",
    "323",
    "i3",
    "218 Active Tourer",
    "M2",
    "430",
    "750",
    "640",
    "M5",
    "M550",
    "435",
    "Z3",
    "114",
    "528",
    "X7",
    "X5 M50",
    "X3 M",
    "iX1",
    "X3 M40",
    "218 Gran Tourer",
    "125",
    "i4",
    "M135",
    "420 Gran Coupé",
    "650",
    "X4 M40",
    "123",
    "440",
    "M240i",
    "135",
    "X6 M",
    "M440",
    "M340i",
    "218 Gran Coupé",
    "X5 M",
    "630",
    "i5",
    "735",
    "X6 M50",
    "iX",
    "iX3",
    "315",
    "M140i",
    "430 Gran Coupé",
    "340",
    "550",
    "M235",
    "320 Gran Turismo",
    "M6",
    "220 Active Tourer",
    "745",
    "216",
    "M850",
    "Z4 M",
    "216 Active Tourer",
    "220 Gran Tourer",
    "225",
    "635",
    "518",
    "428",
    "728",
    "128",
    "840",
    "M340d",
    "225 Active Tourer",
    "645",
    "2002",
    "220 Gran Coupé",
    "X4 M",
    "545",
    "418",
    "223 Active Tourer",
    "230",
    "530 Gran Turismo",
    "324",
    "M8",
    "XM",
    "130",
    "630 Gran Turismo",
    "X7 M50",
    "850",
    "i7",
    "640 Gran Coupé",
    "216 Gran Tourer",
    "330 Gran Turismo",
    "i8",
    "440 Gran Coupé",
    "Z1",
    "535 Gran Turismo",
    "iX2",
    "2er Gran Coupé",
    "640 Gran Turismo",
    "425",
    "318 Gran Turismo",
    "X7 M60",
    "X5 M60",
    "M760",
    "760",
    "Z3 M",
    "435 Gran Coupé",
    "228",
    "418 Gran Coupé",
    "524",
    "328 Gran Turismo",
    "335 Gran Turismo",
    "650 Gran Coupé",
    "X3 M50",
    "X6 M60",
    "Z8",
    "ActiveHybrid 5",
    "1er M Coupé",
    "428 Gran Coupé",
    "633",
    "628",
    "620 Gran Turismo",
    "520 Gran Turismo",
    "425 Gran Coupé",
    "732",
    "550 Gran Turismo",
    "325 Gran Turismo",
    "214 Active Tourer",
    "725",
    "340 Gran Turismo",
    "ActiveHybrid 3",
    "216 Gran Coupé",
    "ActiveHybrid X6",
    "ActiveHybrid 7",
  ],
  "Mercedes-Benz": [
    "Other",
    "C 180",
    "C 200",
    "E 220",
    "C 220",
    "Sprinter",
    "Vito",
    "E 200",
    "A 180",
    "E 350",
    "B 180",
    "B 200",
    "E 300",
    "A 200",
    "C 300",
    "E 320",
    "190",
    "GLC 300",
    "A 160",
    "V 300",
    "V 250",
    "GLC 220",
    "E 250",
    "200",
    "Viano",
    "GLE 350",
    "C 250",
    "220",
    "GLA 200",
    "CLA 200",
    "SLK 200",
    "CLS 350",
    "ML 350",
    "A 250",
    "E 280",
    "S 500",
    "S 350",
    "A 150",
    "A 170",
    "230",
    "CLK 200",
    "C 43 AMG",
    "Citan",
    "GLC 250",
    "ML 320",
    "CLA 180",
    "C 63 AMG",
    "300",
    "GLC 200",
    "G 63 AMG",
    "S 320",
    "CLA 250",
    "E 240",
    "CLA 220",
    "A 140",
    "E 400",
    "V 220",
    "GLK 220",
    "CLK 320",
    "E 500",
    "GLA 250",
    "CLA 200 Shooting Brake",
    "GLB 200",
    "EQA",
    "CL 500",
    "A 220",
    "250",
    "B 170",
    "C 350",
    "GLA 180",
    "280",
    "C 320",
    "SL 500",
    "B 250",
    "320",
    "CLS 500",
    "E 230",
    "GLA 220",
    "GLE 450",
    "CLA 180 Shooting Brake",
    "SLK 230",
    "350",
    "GLE 400",
    "A 45 AMG",
    "E 270",
    "AMG GT",
    "GLC 43 AMG",
    "EQE",
    "ML 280",
    "E 63 AMG",
    "GLK 350",
    "CLA 250 Shooting Brake",
    "EQB",
    "CLK 230",
    "CLS 320",
    "S 63 AMG",
    "B 220",
    "G 500",
    "ML 270",
    "CLA 45 AMG",
    "C 280",
    "C 230",
    "T-Class",
    "CLS 63 AMG",
    "S 400",
    "C 400",
    "GLE 63 AMG",
    "GLC 350",
    "A 35 AMG",
    "GLC 400",
    "E 53 AMG",
    "500",
    "C 240",
    "GLE 300",
    "450",
    "GL 350",
    "Vaneo",
    "SL 350",
    "CLA 220 Shooting Brake",
    "EQS",
    "S 300",
    "CLK 240",
    "GLC 63 AMG",
    "EQV",
    "S 450",
    "560",
    "CLK 500",
    "GLK 250",
    "EQC",
    "240",
    "E 450",
    "S 580",
    "CLS 400",
    "EQE SUV",
    "GLB 220",
    "G 350",
    "ML 250",
    "R 350",
    "SL 300",
    "GLE 53 AMG",
    "SL 280",
    "GLS 350",
    "S 280",
    "A 190",
    "S 560",
    "GLS 400",
    "CLA 35 AMG",
    "MB 100",
    "G 400",
    "ML 63 AMG",
    "260",
    "SL 63 AMG",
    "GLS 450",
    "R 320",
    "GLB 180",
    "B 160",
    "E 43 AMG",
    "SL 320",
    "GLB 250",
    "GLE 250",
    "AMG GT S",
    "ML 500",
    "S 600",
    "ML 420",
    "ML 300",
    "G 300",
    "C 270",
    "380",
    "B 150",
    "GLK 320",
    "CLK 280",
    "270",
    "X 250",
    "CLS 250",
    "CLC 200",
    "E 55 AMG",
    "GLK 200",
    "X 350",
    "CE 230",
    "CLS 350 Shooting Brake",
    "CLC 180",
    "S 430",
    "CLS 450",
    "CLK 350",
    "GLE 43 AMG",
    "GL 420",
    "CLA Shooting Brake",
    "GL 500",
    "CLA 45 AMG Shooting Brake",
    "GLS 63",
    "SL 55 AMG",
    "CLK 220",
    "CLE 200",
    "S 420",
    "AMG GT R",
    "CE 300",
    "C 32 AMG",
    "R 280",
    "GLE 500",
    "S 250",
    "CL 63 AMG",
    "C 450 AMG",
    "CLS 220",
    "E 420",
    "SLK 350",
    "CLE 300",
    "CLA 35 AMG Shooting Brake",
    "SLK 280",
    "G 320",
    "SL 43 AMG",
    "GLB 35 AMG",
    "GL 320",
    "GL 450",
    "G 450",
    "SL 450",
    "420",
    "CLK 270",
    "CLC 220",
    "SLK 320",
    "E 430",
    "CLS 53 AMG",
    "G 230",
    "400",
    "600",
    "ML 400",
    "S 65 AMG",
    "SLK 250",
    "CLS 55 AMG",
    "ML 430",
    "CL 600",
    "GLA 45 AMG",
    "S 260",
    "SLC 200",
    "GLA 35 AMG",
    "G 250",
    "CL 200",
    "EQS SUV",
    "GLC 450",
    "GLS 600",
    "A 210",
    "G 580",
    "AMG GT C",
    "R 500",
    "R 300",
    "CE 220",
    "CLK 430",
    "CLE 220",
    "SL 560",
    "V 230",
    "GLE 580",
    "C 36 AMG",
    "E 260",
    "SLS AMG",
    "G 55 AMG",
    "CL 55 AMG",
    "EQT",
    "CLS Shooting Brake",
    "CL 180",
    "SL 380",
    "GLS 580",
    "CLS 300",
    "C 160",
    "CLE 53 AMG",
    "V 200",
    "SLK 55 AMG",
    "CLK 55 AMG",
    "ML 450",
    "CLC 230",
    "ML 55 AMG",
    "S 550",
    "SL 400",
    "SLC 180",
    "GLK 280",
    "CLS 280",
    "S 680",
    "GL 63 AMG",
    "Vario",
    "G 290",
    "SL 600",
    "CE 280",
    "G 270",
    "S 55",
    "SLR",
    "SLC 280",
    "G 280",
    "SLK 32 AMG",
    "S 650",
    "CLC 160",
    "C 55 AMG",
    "290",
    "ML 230",
    "CLS 500 Shooting Brake",
    "CLS 400 Shooting Brake",
    "CL 420",
    "SLC 250",
    "CE 200",
    "SL 65 AMG",
    "GLS 500",
    "CLK 63 AMG",
    "CE 320",
    "CL 220",
    "CL 230",
    "CLS 63 AMG Shooting Brake",
    "SLC 43 AMG",
    "GLK 300",
    "G 240",
    "E 50",
    "E 290",
    "CLS 220 Shooting Brake",
    "CLE 450",
    "SLK 300",
    "SLC 300",
    "CLC 350",
    "C 30 AMG",
    "B Electric Drive",
    "CLS 250 Shooting Brake",
    "G 65 AMG",
    "SL 60 AMG",
    "V 280",
    "CL 65 AMG",
    "CLC 250",
    "R 63 AMG",
    "GL 55 AMG",
    "416",
    "CL 160",
    "SL 250",
  ],
  Skoda: [
    "Octavia",
    "Fabia",
    "Superb",
    "Kodiaq",
    "Karoq",
    "Kamiq",
    "Scala",
    "Citigo",
    "Yeti",
    "Roomster",
    "Rapid",
    "Enyaq",
    "Praktik",
    "Felicia",
    "Other",
    "Elroq",
    "105",
    "Pick-up",
    "120",
    "Favorit",
    "130",
  ],
  Smart: ["ForTwo", "ForFour", "Other", "Roadster", "#1", "#3", "Crossblade"],
  Renault: [
    "Clio",
    "Twingo",
    "Megane",
    "Captur",
    "Kangoo",
    "Scenic",
    "Trafic",
    "Other",
    "ZOE",
    "Master",
    "Modus",
    "Kadjar",
    "Laguna",
    "Espace",
    "Grand Scenic",
    "Koleos",
    "Talisman",
    "Austral",
    "Arkana",
    "Express",
    "R 5",
    "Grand Modus",
    "Rafale",
    "Symbioz",
    "Twizy",
    "Grand Espace",
    "Wind",
    "R 4",
    "R 19",
    "Alaskan",
    "Vel Satis",
    "R 11",
    "Kangoo E-TECH",
    "Rapid",
    "Latitude",
    "Alpine A110",
    "Safrane",
    "Alpine A310",
    "Avantime",
    "Grand Kangoo",
    "Mascott",
    "Fluence",
    "R 21",
    "R 9",
    "Fuego",
    "Coupe",
    "Alpine V6",
    "R 6",
    "R 30",
    "R 20",
  ],
  Fiat: [
    "Panda",
    "500",
    "Punto",
    "Ducato",
    "500X",
    "Tipo",
    "Grande Punto",
    "500L",
    "Other",
    "500C",
    "Punto Evo",
    "500L Cross",
    "Doblo",
    "Bravo",
    "Qubo",
    "500e",
    "Fiorino",
    "Scudo",
    "Freemont",
    "Seicento",
    "Idea",
    "Stilo",
    "Sedici",
    "Talento",
    "500L Trekking",
    "500S",
    "Croma",
    "500L Urban",
    "124 Spider",
    "Multipla",
    "600",
    "126",
    "500L Living",
    "Cinquecento",
    "Uno",
    "New Panda",
    "Barchetta",
    "Strada",
    "Coupe",
    "Ulysse",
    "Fullback",
    "Linea",
    "500L Wagon",
    "Ritmo",
    "Tempra",
    "Topolino",
    "127",
    "600e",
    "Brava",
    "Regata",
    "Marea",
    "X 1/9",
    "131",
    "Spider Europa",
    "Palio",
    "124",
    "130",
    "Albea",
    "Dino",
  ],
  Seat: [
    "Ibiza",
    "Leon",
    "Arona",
    "Ateca",
    "Alhambra",
    "Altea",
    "Tarraco",
    "Mii",
    "Arosa",
    "Toledo",
    "Exeo",
    "Cordoba",
    "Other",
    "Marbella",
    "Inca",
    "Terra",
    "Malaga",
  ],
  Nissan: [
    "Qashqai",
    "Micra",
    "Juke",
    "X-Trail",
    "Navara",
    "Note",
    "Leaf",
    "Primera",
    "Qashqai+2",
    "NV200",
    "Almera",
    "Pulsar",
    "Pathfinder",
    "Pixo",
    "Almera Tino",
    "Primastar",
    "Patrol",
    "Murano",
    "350Z",
    "Townstar",
    "NV400",
    "King Cab",
    "Other",
    "Evalia",
    "GT-R",
    "NP 300",
    "Skyline",
    "NV300",
    "Terrano",
    "200 SX",
    "370Z",
    "Tiida",
    "Cabstar",
    "Kubistar",
    "e-NV200",
    "Cube",
    "Interstar",
    "Ariya",
    "PickUp",
    "Sunny",
    "100 NX",
    "300 ZX",
    "Maxima",
    "Quest",
    "280 ZX",
    "Armada",
    "Bluebird",
    "Vanette",
    "Urvan",
    "Silvia",
    "Prairie",
    "Frontier",
    "Altima",
    "Serena",
  ],
  Hyundai: [
    "i30",
    "Tucson",
    "i10",
    "i20",
    "Kona",
    "Getz",
    "Santa Fe",
    "ix35",
    "ix20",
    "IONIQ 5",
    "i40",
    "Bayon",
    "Atos",
    "IONIQ",
    "IONIQ 6",
    "H-1",
    "Other",
    "Matrix",
    "Staria",
    "Coupe",
    "H-1 Starex",
    "Accent",
    "Terracan",
    "Grand Santa Fe",
    "ix55",
    "Sonata",
    "H350",
    "Veloster",
    "Galloper",
    "Elantra",
    "Genesis",
    "Nexo",
    "Veracruz",
    "Trajet",
    "Azera",
    "Grandeur",
    "Santamo",
    "Lantra",
    "S-Coupe",
    "Pony",
  ],
  Toyota: [
    "Yaris",
    "Corolla",
    "Other",
    "Aygo (X)",
    "Avensis",
    "RAV 4",
    "C-HR",
    "Auris",
    "Verso",
    "Corolla Verso",
    "Land Cruiser",
    "Yaris Cross",
    "Proace (Verso)",
    "Hilux",
    "Prius",
    "IQ",
    "PROACE CITY",
    "Celica",
    "Auris Touring Sports",
    "Corolla Cross",
    "Verso-S",
    "Camry",
    "Supra",
    "Prius+",
    "bZ4X",
    "Starlet",
    "MR 2",
    "GT86",
    "Hiace",
    "GR86",
    "Carina",
    "Urban Cruiser",
    "Paseo",
    "Highlander",
    "Dyna",
    "Previa",
    "Crown",
    "Sienna",
    "Mirai",
    "Avensis Verso",
    "FJ",
    "4-Runner",
    "Picnic",
    "Tacoma",
    "Tundra",
    "Sequoia",
    "Alphard",
    "Tercel",
    "Avalon",
  ],
  Citroën: [
    "C3",
    "Berlingo",
    "C1",
    "C4",
    "Jumper",
    "C2",
    "C3 Aircross",
    "C4 Picasso",
    "C5 Aircross",
    "C5",
    "C3 Picasso",
    "Jumpy",
    "SpaceTourer",
    "C4 Cactus",
    "DS3",
    "Nemo",
    "Grand C4 Picasso / SpaceTourer",
    "Xsara Picasso",
    "Other",
    "C8",
    "2 CV",
    "C4 SpaceTourer",
    "SAXO",
    "Xsara",
    "DS4",
    "DS5",
    "C5 X",
    "C-Crosser",
    "C4 X",
    "DS",
    "C4 Aircross",
    "C6",
    "AX",
    "C-Zero",
    "XM",
    "Xantia",
    "CX",
    "AMI",
    "BX",
    "ë-Berlingo",
    "ZX",
    "GSA",
    "C-Elysée",
    "SM",
    "DS4 Crossback",
    "E-MEHARI",
    "Evasion",
  ],
  Peugeot: [
    "206",
    "208",
    "308",
    "207",
    "3008",
    "2008",
    "307",
    "5008",
    "Boxer",
    "508",
    "107",
    "Partner",
    "Expert",
    "407",
    "Other",
    "108",
    "1007",
    "106",
    "Partner Tepee",
    "Traveller",
    "Rifter",
    "RCZ",
    "306",
    "807",
    "Bipper",
    "205",
    "607",
    "406",
    "Bipper Tepee",
    "4007",
    "408",
    "e-208",
    "Expert Tepee",
    "504",
    "iOn",
    "204",
    "4008",
    "405",
    "505",
    "605",
    "309",
    "404",
    "806",
    "104",
    "304",
    "305",
  ],
  Kia: [
    "Sportage",
    "Picanto",
    "Other",
    "Sorento",
    "cee'd / Ceed",
    "Rio",
    "cee'd Sportswagon",
    "XCeed",
    "Stonic",
    "pro cee'd / ProCeed",
    "Niro",
    "Venga",
    "EV6",
    "Carens",
    "Soul",
    "Optima",
    "Carnival",
    "Stinger",
    "EV3",
    "Cerato",
    "Magentis",
    "EV9",
    "Opirus",
    "Mentor",
    "K2500",
    "Retona",
    "Pride",
    "Shuma",
    "Elan",
    "Sephia",
    "Pregio",
    "Borrego",
    "Joice",
  ],
  Dacia: [
    "Sandero",
    "Duster",
    "Logan",
    "Spring",
    "Jogger",
    "Dokker",
    "Lodgy",
    "Other",
    "Logan Pick-Up",
    "Pick Up",
  ],
  Porsche: [
    "Cayenne",
    "Macan",
    "911",
    "Panamera",
    "992",
    "Boxster",
    "Taycan",
    "991",
    "Cayman",
    "997",
    "996",
    "944",
    "924",
    "928",
    "964",
    "993",
    "356",
    "Other",
    "914",
    "Carrera GT",
    "930",
    "968",
    "912",
    "918",
  ],
  MINI: [
    "Cooper",
    "ONE",
    "Cooper S",
    "Other",
    "Cooper SE",
    "Cooper C",
    "Cooper D Countryman",
    "Cooper Cabrio",
    "Cooper D",
    "John Cooper Works",
    "Cooper Countryman",
    "Cooper S Countryman",
    "Cooper S Cabrio",
    "Cooper Clubman",
    "One Cabrio",
    "Cooper SD",
    "Cooper SD Countryman",
    "One D",
    "Cooper SE Countryman",
    "Cooper S Clubman",
    "One Clubman",
    "Cooper D Clubman",
    "One D Countryman",
    "John Cooper Works Cabrio",
    "One Countryman",
    "John Cooper Works Countryman",
    "Cooper SD Clubman",
    "One D Clubman",
    "One First",
    "John Cooper Works Clubman",
    "Cooper D Cabrio",
    "Clubvan",
    "Cooper Roadster",
    "Cooper E",
    "Cooper Coupé",
    "John Cooper Works Paceman",
    "Cooper S Coupé",
    "Cooper SD Cabrio",
    "Cooper D Paceman",
    "Cooper S Paceman",
    "Cooper SD Paceman",
    "Cooper Paceman",
    "Cooper E Countryman",
    "1300",
    "1000",
    "John Cooper Works Coupé",
    "Cooper S Roadster",
    "Cooper SD Roadster",
    "John Cooper Works Roadster",
    "Aceman",
    "Cooper C Countryman",
  ],
  Mazda: [
    "2",
    "CX-5",
    "3",
    "6",
    "5",
    "MX-5",
    "CX-30",
    "CX-3",
    "323",
    "CX-60",
    "CX-7",
    "Other",
    "MX-30",
    "Premacy",
    "Demio",
    "RX-8",
    "2 Hybrid",
    "626",
    "CX-80",
    "Tribute",
    "CX-9",
    "MX-3",
    "BT-50",
    "RX-7",
    "121",
    "E series",
    "Xedos",
    "MPV",
    "B series",
    "MX-6",
    "RX-6",
    "929",
  ],
  Jeep: [
    "Renegade",
    "Compass",
    "Grand Cherokee",
    "Wrangler",
    "Cherokee",
    "Avenger",
    "Other",
    "CJ",
    "Commander",
    "Willys",
    "Patriot",
    "Gladiator",
    "Wagoneer",
    "Comanche",
  ],
  Cupra: [
    "Formentor",
    "Leon",
    "Ateca",
    "Born",
    "Terramar",
    "Tavascan",
    "Other",
  ],
  Volvo: [
    "XC60",
    "XC90",
    "V60",
    "XC40",
    "V40",
    "V70",
    "Other",
    "V50",
    "S60",
    "V90",
    "XC70",
    "C30",
    "S80",
    "EX30",
    "C70",
    "S40",
    "V90 Cross Country",
    "850",
    "V60 Cross Country",
    "940",
    "C40",
    "S90",
    "V40 Cross Country",
    "240",
    "960",
    "EX90",
    "740",
    "360",
    "Amazon",
    "S70",
    "945",
    "244",
    "760",
    "245",
    "EX40",
    "S60 Cross Country",
    "480",
    "264",
    "340",
    "745",
    "440",
    "460",
    "780",
    "944",
  ],
  Lancia: [
    "Ypsilon",
    "MUSA",
    "Delta",
    "Other",
    "Thema",
    "Voyager",
    "Thesis",
    "Phedra",
    "Kappa",
    "Beta",
    "Lybra",
    "Prisma",
    "Fulvia",
    "Dedra",
    "Flavia",
    "Flaminia",
    "Gamma",
    "Zeta",
  ],
  Honda: [
    "Civic",
    "Jazz",
    "CR-V",
    "Accord",
    "HR-V",
    "FR-V",
    "CRX",
    "Prelude",
    "e",
    "ZR-V",
    "Stream",
    "Logo",
    "Insight",
    "Other",
    "Odyssey",
    "CR-Z",
    "Aerodeck",
    "S2000",
    "Integra",
    "Legend",
    "Shuttle",
    "e:Ny1",
    "Ridgeline",
    "NSX",
    "Pilot",
    "City",
    "Concerto",
  ],
  Suzuki: [
    "Swift",
    "Jimny",
    "Vitara",
    "Ignis",
    "Grand Vitara",
    "SX4",
    "Alto",
    "Splash",
    "(SX4) S-Cross",
    "Wagon R+",
    "SJ Samurai",
    "Baleno",
    "Across",
    "Liana",
    "Celerio",
    "Other",
    "Swace",
    "Kizashi",
    "LJ",
    "Super-Carry",
    "Cappuccino",
    "Carry",
    "X-90",
  ],
  Mitsubishi: [
    "Space Star",
    "Colt",
    "Outlander",
    "ASX",
    "Pajero",
    "Eclipse Cross",
    "Lancer",
    "L200",
    "Carisma",
    "Other",
    "Eclipse",
    "Grandis",
    "Plug-in Hybrid Outlander",
    "Galant",
    "Pajero Pinin",
    "L300",
    "i-MiEV",
    "Pick-up",
    "3000 GT",
    "Canter",
    "Space Runner",
    "Space Wagon",
    "Diamante",
    "Space Gear",
    "Montero",
    "L400",
  ],
  "Land Rover": [
    "Range Rover Evoque",
    "Range Rover Sport",
    "Discovery Sport",
    "Defender",
    "Range Rover Velar",
    "Range Rover",
    "Discovery",
    "Freelander",
    "Serie III",
    "Other",
    "Serie II",
    "Serie I",
  ],
  "Alfa Romeo": [
    "Stelvio",
    "Giulietta",
    "MiTo",
    "Giulia",
    "Spider",
    "Alfa 159",
    "Alfa 147",
    "Alfa 156",
    "GT",
    "GTV",
    "Brera",
    "Tonale",
    "Other",
    "Alfa 166",
    "Junior",
    "Alfetta",
    "Alfa 146",
    "Alfa 145",
    "Alfa 164",
    "4C",
    "Alfa 155",
    "Alfa 33",
    "Alfa 75",
    "Sprint",
    "Alfasud",
  ],
  Tesla: ["Model 3", "Model Y", "Model S", "Model X", "Other"],
  Chevrolet: [
    "Captiva",
    "Matiz",
    "Spark",
    "Aveo",
    "Camaro",
    "Cruze",
    "Orlando",
    "Other",
    "G",
    "Kalos",
    "Chevy Van",
    "Blazer",
    "Tahoe",
    "Silverado",
    "Nubira",
    "Epica",
    "Trax",
    "Lacetti",
    "Express",
    "Caprice",
    "Trailblazer",
    "Impala",
    "Rezzo",
    "C1500",
    "Suburban",
    "S-10",
    "HHR",
    "Chevelle",
    "K30",
    "K1500",
    "Astro",
    "Avalanche",
    "Alero",
    "El Camino",
    "Evanda",
    "Malibu",
    "SSR",
    "Colorado",
    "Beretta",
    "Traverse",
    "Cavalier",
    "Niva",
    "Lumina",
  ],
  Subaru: [
    "Forester",
    "Impreza",
    "XV",
    "Legacy",
    "Outback",
    "Justy",
    "WRX STI",
    "BRZ",
    "Crosstrek",
    "Levorg",
    "SVX",
    "Other",
    "Trezia",
    "Solterra",
    "Libero",
    "B9 Tribeca",
    "Vivio",
    "Tribeca",
    "XT",
  ],
  Jaguar: [
    "XF",
    "F-Pace",
    "E-Pace",
    "XE",
    "X-Type",
    "S-Type",
    "XJ",
    "F-Type",
    "XK",
    "XK8",
    "I-Pace",
    "XJ12",
    "E-Type",
    "XKR",
    "XJ8",
    "XJ40",
    "Daimler",
    "XJS",
    "XJ6",
    "Other",
    "MK II",
    "XJR",
    "XJSC",
  ],
  Dodge: [
    "RAM",
    "Challenger",
    "Durango",
    "Charger",
    "Caliber",
    "Other",
    "Nitro",
    "Journey",
    "Grand Caravan",
    "Viper",
    "Magnum",
    "Dakota",
    "Coronet",
    "Avenger",
    "Dart",
    "Demon",
    "Stealth",
  ],
  Trabant: ["601", "Other"],
  Iveco: ["Other", "Massif"],
  Chrysler: [
    "300C",
    "PT Cruiser",
    "Voyager",
    "Sebring",
    "Grand Voyager",
    "Crossfire",
    "Other",
    "Pacifica",
    "Stratus",
    "200",
    "300 M",
    "Le Baron",
    "Neon",
    "New Yorker",
    "Imperial",
    "ES",
    "Vision",
    "Daytona",
    "Aspen",
    "Viper",
  ],
  Abarth: [
    "595",
    "500",
    "595 Turismo",
    "595 Competizione",
    "695",
    "595C",
    "500C",
    "Other",
    "124 Spider",
    "695C",
    "Grande Punto",
    "Punto Evo",
  ],
  Saab: ["9-3", "9-5", "900", "96", "9000", "9-7X", "Other"],
  Daihatsu: [
    "Sirion",
    "Cuore",
    "Terios",
    "MATERIA",
    "TREVIS",
    "Hijet",
    "YRV",
    "Charade",
    "Copen",
    "Other",
    "Applause",
    "Move",
    "Feroza/Sportrak",
    "Freeclimber",
  ],
  Lada: [
    "Niva",
    "Taiga",
    "Other",
    "Kalina",
    "1200",
    "Vesta",
    "2107",
    "Samara",
    "Priora",
    "Nova",
    "Granta",
    "2111",
    "Aleko",
    "Urban",
    "111",
  ],
  "DS Automobiles": [
    "DS7 (Crossback)",
    "DS3",
    "DS4",
    "DS3 Crossback",
    "DS5",
    "Other",
    "DS4 Crossback",
    "DS9",
  ],
  Maserati: [
    "Ghibli",
    "Levante",
    "Quattroporte",
    "Grecale",
    "Granturismo",
    "Other",
    "GranCabrio",
    "MC20",
    "4200",
    "3200",
    "Gransport",
    "Spyder",
    "Biturbo",
    "Merak",
    "222",
    "Indy",
  ],
  MG: [
    "EHS",
    "ZS",
    "HS",
    "MG4",
    "MG3",
    "Other",
    "MG5",
    "MGB",
    "MGF",
    "Marvel R",
    "TF",
    "MGA",
    "Midget",
    "TD",
    "ZR",
  ],
  Lamborghini: [
    "Urus",
    "Huracán",
    "Aventador",
    "Gallardo",
    "Revuelto",
    "Murciélago",
    "Espada",
    "Other",
    "LM",
    "Jalpa",
  ],
  Lexus: [
    "Other",
    "UX",
    "NX 350",
    "RX 450",
    "NX 300",
    "IS 300",
    "LBX",
    "NX 450",
    "GS 300",
    "GS 450",
    "ES 300",
    "IS 250",
    "RX 500",
    "CT 200h",
    "IS 200",
    "LC 500",
    "RZ",
    "RX 400",
    "RX 350",
    "RX 300",
    "SC 430",
    "LM",
    "IS 220",
    "LS 400",
    "GS 430",
    "IS 350",
    "LS 430",
    "LS 500",
    "LX 570",
    "LX 600",
    "RC 200",
    "GX 460",
    "GS 350",
    "GS 250",
    "NX 200",
    "RC 300",
    "RC F",
    "SC 400",
    "LX 500",
    "LS 460",
    "LC 500h",
    "IS-F",
  ],
  Aixam: ["City", "Other", "Crossline"],
  "Lynk&Co": ["01", "Other"],
  Isuzu: ["D-Max", "Other", "Trooper", "PICK UP", "Campo"],
  Bentley: [
    "Continental GT",
    "Bentayga",
    "Continental GTC",
    "Flying Spur",
    "Continental Flying Spur",
    "Other",
    "Continental",
    "Mulsanne",
    "Arnage",
    "Azure",
    "Brooklands",
    "Turbo R",
    "S2",
    "Continental Supersports",
  ],
  Rover: [
    "Other",
    "75",
    "200",
    "45",
    "25",
    "100",
    "216",
    "400",
    "Metro",
    "416",
    "SD",
    "820",
    "620",
    "600",
    "213",
  ],
  MAN: ["TGE", "Other"],
  Ssangyong: [
    "REXTON",
    "Korando",
    "Tivoli",
    "Kyron",
    "MUSSO",
    "Rodius",
    "Actyon",
    "XLV",
    "Torres",
  ],
  Cadillac: [
    "XT4",
    "Escalade",
    "Deville",
    "Eldorado",
    "SRX",
    "CTS",
    "Fleetwood",
    "Seville",
    "Other",
    "ATS",
    "XT5",
    "XLR",
  ],
  Polestar: ["2", "Other", "1"],
  Ferrari: [
    "812",
    "SF90",
    "F8",
    "360",
    "Roma",
    "296 GTB",
    "California",
    "Purosangue",
    "Portofino",
    "F430",
    "488 GTB",
    "LaFerrari",
    "GTC4Lusso",
    "458",
    "Mondial",
    "296 GTS",
    "308",
    "Testarossa",
    "488 Pista",
    "488 Spider",
    "208",
    "348",
    "512",
    "F355",
    "Other",
    "F12",
    "FF",
    "328",
    "456",
    "612",
    "400",
    "575",
    "550",
    "330",
    "246",
    "Enzo Ferrari",
    "Daytona",
    "599 GTB",
    "365",
    "250",
    "Dino GT4",
    "Monza",
    "Superamerica",
  ],
  Alpine: ["A110", "A290"],
  "Aston Martin": [
    "V8 Vantage",
    "DBX",
    "DBS",
    "DB11",
    "Other",
    "DB12",
    "V12 Vantage",
    "DB",
    "Vanquish",
    "DB7",
    "DB9",
    "Rapide",
    "Virage",
    "Lagonda",
    "Cygnet",
  ],
  Daewoo: [
    "Matiz",
    "Kalos",
    "Lanos",
    "Nubira",
    "Other",
    "Evanda",
    "Espero",
    "Rezzo",
    "Lacetti",
  ],
  Ligier: ["Other", "JS 50", "JS 60", "X - Too", "JS 50 L", "Ambra", "Myli"],
  Hummer: ["H2", "H3", "H1"],
  GWM: ["Ora 03", "Wey 05", "Wey 03"],
  Corvette: ["C8", "C7", "C6", "C3", "C4", "C2", "Z06", "C1", "ZR 1", "C5"],
  "Rolls-Royce": [
    "Cullinan",
    "Phantom",
    "Spectre",
    "Ghost",
    "Other",
    "Wraith",
    "Corniche",
    "Silver Cloud",
    "Silver Shadow",
    "Silver Spirit",
    "Dawn",
    "Silver Spur",
  ],
  SWM: ["Other", "G01"],
  ALPINA: [
    "B3",
    "D3",
    "D5",
    "B5",
    "XD3",
    "B7",
    "XB7",
    "B4",
    "B10",
    "B8",
    "D3 S",
    "B6",
    "XD4",
    "Other",
    "D4 S",
    "D4",
  ],
  Infiniti: [
    "Q30",
    "FX",
    "EX30",
    "Q70",
    "Q50",
    "QX70",
    "Q60",
    "QX80",
    "QX30",
    "M30",
    "G37",
  ],
  Piaggio: ["Porter", "Other", "APE", "APE TM"],
  Maxus: [
    "Deliver 9",
    "Other",
    "eDeliver 3",
    "Euniq 6",
    "Euniq 5",
    "Mifa 9",
    "T90",
  ],
  BAIC: [
    "Other",
    "X55",
    "Senova X35",
    "Beijing X75",
    "BJ40",
    "Senova X55",
    "Senova X25",
    "BJ20",
  ],
  "Austin Healey": ["Other"],
  DFSK: [
    "Fengon 500",
    "Fengon 5",
    "Forthing 5",
    "Other",
    "Fengon 600",
    "Forthing 4",
    "Fengon 7",
    "Fengon 580",
    "Fengon",
    "Glory",
    "Seres 3",
    "K01",
    "Rich 6",
  ],
  INEOS: ["Grenadier"],
  GMC: ["Sierra", "Savana", "Other", "Yukon", "Safari"],
  BYD: ["ATTO 3", "SEAL", "DOLPHIN", "TANG", "Other", "Seal U", "HAN"],
  Lotus: [
    "Elise",
    "Exige",
    "Esprit",
    "Emira",
    "Elan",
    "Eletre",
    "Europa",
    "Other",
    "Super Seven",
    "340 R",
    "Evora",
  ],
  McLaren: [
    "720S",
    "570S",
    "GT",
    "Artura",
    "Other",
    "600LT",
    "570GT",
    "620R",
    "750S",
    "650S",
    "650S Coupé",
    "650S Spider",
    "765LT",
    "MP4-12C",
  ],
  AC: ["Other"],
  Leapmotor: ["T03", "C10"],
  Plymouth: ["Other", "Prowler"],
  Pontiac: [
    "Firebird",
    "Trans Am",
    "Bonneville",
    "Fiero",
    "Other",
    "GTO",
    "Solstice",
  ],
  Triumph: ["Spitfire", "TR6", "TR4", "Other", "TR3"],
  Genesis: ["G70", "GV70", "GV80", "G80", "GV60"],
  Morgan: ["Plus 4", "Plus 6", "4/4", "Plus 8", "Other", "3 Wheeler"],
  ORA: ["Funky Cat", "Other"],
  Wartburg: ["353", "Other", "311"],
  Elaris: ["Other"],
  Lincoln: ["Continental", "Town Car", "Navigator", "Other"],
  Microcar: ["M.Go", "DUÈ", "Other", "MC1", "MC2", "M-8", "Flex"],
  NSU: ["Other"],
  Tata: ["Xenon", "Other", "Indica"],
  Borgward: ["Other"],
  Aiways: ["U5"],
  XPENG: ["G9", "P7", "Other"],
  Maybach: ["Other", "S650", "57", "62"],
  JAC: ["8 Pro", "Other"],
  Oldsmobile: ["Other", "Toronado", "Delta 88", "Cutlass", "Bravada"],
  Seres: ["3", "5"],
  Cobra: ["Other"],
  Austin: ["Other"],
  Chatenet: ["Other"],
  "e.GO": ["Other", "Life"],
  DeTomaso: ["Pantera"],
  Barkas: ["B1000", "Other"],
  Bugatti: ["Other", "Chiron"],
  Buick: ["Other", "Skylark", "Roadmaster", "Enclave", "Electra", "Century"],
  XEV: ["Other", "YOYO"],
  Fisker: ["Karma", "Ocean"],
  Donkervoort: ["S8", "D8", "Other"],
  TECHART: ["Other"],
  Westfalia: [],
  Casalini: ["Other"],
  NIO: ["EL7", "ET5"],
  Westfield: ["Other"],
  Grecav: ["Sonique"],
  Lucid: ["Air"],
  Microlino: ["Dolce", "Competizione", "Pioneer"],
  WEY: ["Coffee 01", "Coffee 02"],
  TVR: ["Other", "Tuscan"],
  LEVC: ["Other"],
  Wiesmann: ["MF 3", "MF 4", "Other", "MF 5"],
  Brilliance: ["BS6", "BS4", "BS2"],
  Acura: ["RSX", "NSX"],
  Talbot: ["Samba"],
  Zhidou: ["Other"],
  "Asia Motors": ["Rocsta"],
};

const cityTranslations: Record<string, string> = {
  Берлин: "Berlin",
  Гамбург: "Hamburg",
  Мюнхен: "Munich",
  Кёльн: "Cologne",
  Франкфурт: "Frankfurt",
  Штутгарт: "Stuttgart",
  Дюссельдорф: "Düsseldorf",
  Дортмунд: "Dortmund",
  Эссен: "Essen",
  Бремен: "Bremen",
  Париж: "Paris",
  Марсель: "Marseille",
  Лион: "Lyon",
  Тулуза: "Toulouse",
  Ницца: "Nice",
  Нант: "Nantes",
  Страсбург: "Strasbourg",
  Монпелье: "Montpellier",
  Бордо: "Bordeaux",
  Лилль: "Lille",
  Рим: "Rome",
  Милан: "Milan",
  Неаполь: "Naples",
  Турин: "Turin",
  Палермо: "Palermo",
  Генуя: "Genoa",
  Болонья: "Bologna",
  Флоренция: "Florence",
  Венеция: "Venice",
  Верона: "Verona",
  Мадрид: "Madrid",
  Барселона: "Barcelona",
  Валенсия: "Valencia",
  Севилья: "Seville",
  Сарагоса: "Zaragoza",
  Малага: "Malaga",
  Мурсия: "Murcia",
  "Пальма-де-Майорка": "Palma de Mallorca",
  Бильбао: "Bilbao",
  Аликанте: "Alicante",
  Лондон: "London",
  Бирмингем: "Birmingham",
  Манчестер: "Manchester",
  Ливерпуль: "Liverpool",
  Лидс: "Leeds",
  Шеффилд: "Sheffield",
  Эдинбург: "Edinburgh",
  Глазго: "Glasgow",
  Бристоль: "Bristol",
  Кардифф: "Cardiff",
  Варшава: "Warsaw",
  Краков: "Krakow",
  Лодзь: "Łódź",
  Вроцлав: "Wrocław",
  Познань: "Poznań",
  Гданьск: "Gdańsk",
  Щецин: "Szczecin",
  Люблин: "Lublin",
  Быдгощ: "Bydgoszcz",
  Катовице: "Katowice",
  Амстердам: "Amsterdam",
  Роттердам: "Rotterdam",
  Гаага: "The Hague",
  Утрехт: "Utrecht",
  Эйндховен: "Eindhoven",
  Гронинген: "Groningen",
  Бреда: "Breda",
  Неймеген: "Nijmegen",
  Алкмар: "Alkmaar",
  Маастрихт: "Maastricht",
  Прага: "Prague",
  Брно: "Brno",
  Острава: "Ostrava",
  Пльзень: "Plzeň",
  Либерец: "Liberec",
  Оломоуц: "Olomouc",
  "Ческе-Будеевице": "České Budějovice",
  "Градец-Кралове": "Hradec Králové",
  "Усти-над-Лабем": "Ústí nad Labem",
  Пардубице: "Pardubice",
  Вена: "Vienna",
  Грац: "Graz",
  Линц: "Linz",
  Зальцбург: "Salzburg",
  Инсбрук: "Innsbruck",
  Клагенфурт: "Klagenfurt",
  Велс: "Wels",
  Штайр: "Steyr",
  "Санкт-Пельтен": "Sankt Pölten",
  Фельдкирх: "Feldkirch",
  Стокгольм: "Stockholm",
  Гётеборг: "Gothenburg",
  Мальмё: "Malmö",
  Уппсала: "Uppsala",
  Вестерос: "Västerås",
  Эребру: "Örebro",
  Линчёпинг: "Linköping",
  Хельсингборг: "Helsingborg",
  Норрчёпинг: "Norrköping",
  Йёнчёпинг: "Jönköping",
};

export const countryCodeToName: { [code: string]: string } = {
  DE: "Germany",
  IT: "Italy",
  LT: "Lithuania",
  CZ: "Czech Republic",
  ES: "Spain",
  EE: "Estonia",
  FR: "France",
  SE: "Sweden",
  SK: "Slovakia",
  UA: "Ukraine",
  RS: "Serbia",
  HR: "Croatia",
  FI: "Finland",
  GR: "Greece",
  AE: "United Arab Emirates",
  MC: "Monaco",
  US: "United States",
  BA: "Bosnia and Herzegovina",
  BG: "Bulgaria",
  SI: "Slovenia",
  ME: "Montenegro",
  AD: "Andorra",
  AL: "Albania",
  TR: "Turkey",
};
export const nameToCountryCode: { [name: string]: string } = Object.keys(
  countryCodeToName
).reduce((acc, code) => {
  const countryName = countryCodeToName[code];
  acc[countryName] = code;
  return acc;
}, {} as { [name: string]: string });

const sellers: Record<string, string> = {
  Частные: "private",
  Диллеры: "dealer",
};

export {
  users,
  lastAdded,
  bodyTypes,
  transmissions,
  fuelTypes,
  driveTypes,
  radius,
  types,
  cityTranslations,
  sellers,
};
