import styles from "./CarCard.module.css";
import { Sedan } from "shared/ui/Sedan/ui/Sedan";
import { Heart } from "shared/ui/Icons/Heart";
import { Stat } from "shared/ui/Icons/Stat";
import React, { useState, useEffect } from "react";
import { sendPatchRequest, sendDeleteRequest } from "shared/api/lib/requests";
import { useNavigate } from "react-router-dom";
import { colors } from "../model/constants";
import { CarCardProps } from "../model/interfaces";
import { useTranslation } from "react-i18next";
import { Popup } from "shared/ui/Popup";
import { Button } from "shared/ui/button";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "shared/helper/localstorage";
import { baseFilterData } from "features/СarFilters/hooks/useCarFiltersData";
import { countryCodeToName } from "features/СarFilters/model/constants";

export function CarCard({
  date,
  title,
  displacement,
  transmission,
  price,
  mileage,
  inner_id,
  color,
  car_picture,
  year,
  shop_url,
  isFavorite,
  seller_country,
}: CarCardProps) {
  const [isFav, setIsFav] = useState(isFavorite);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setIsFav(isFavorite);
  }, [isFavorite]);

  const getFirstImage = () => {
    try {
      const parsedImages = JSON.parse(car_picture);
      if (Array.isArray(parsedImages) && parsedImages.length > 0) {
        return parsedImages[0].url;
      }
    } catch (error) {
      console.error("Error parsing image:", error);
    }
    return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-pKl40cUP8JzODO9dFP5U9KuTSQg1sr20dw&s";
  };

  const imageUrl = getFirstImage();

  const handleFavourite = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        if (isFav) {
          setIsFav(false);
          await sendDeleteRequest("cars-system/favourite", {
            access_token: token,
            inner_id: inner_id,
          });
        } else {
          setIsFav(true);
          await sendPatchRequest("cars-system/favourite", {
            access_token: token,
            inner_id: inner_id,
          });
        }
        window.location.reload();
      } catch (error) {
        console.error("Error handling favourite:", error);
      }
    } else {
      setShowLoginPopup(true);
    }
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleStatClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigate(`/market/${inner_id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const marketSavedFilters = getLocalStorageItem(
      "offersFilterData",
      baseFilterData
    );
    setLocalStorageItem("marketFilterData", marketSavedFilters);
  };

  return (
    <>
      <div
        className={styles.container}
        onClick={() => window.open(shop_url, "_blank")}
      >
        <div style={{ width: 80 }}>
          <div className={styles.carImageWrapper}>
            <img
              className={`${styles.carImage} ${isLoaded ? styles.loaded : ""}`}
              src={imageUrl}
              alt={title}
              onLoad={handleImageLoad}
            />
          </div>
        </div>

        <div className={styles.dateInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>{date.time}</p>
          <p className={styles.smallText}>
            {date.day} {date.month}
          </p>
        </div>

        <div className={styles.titleInfo}>
          <p className={styles.carNameText}>{title}</p>
        </div>

        <div className={styles.yearInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>{year}</p>
        </div>

        <div className={styles.priceInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>€{price}</p>
        </div>

        <div className={styles.mileageInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>{mileage} km</p>
        </div>

        <div className={styles.engineInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>{t(transmission)}</p>
          <p className={styles.smallText}>
            {displacement} {t("liter")}
          </p>
        </div>

        <div className={styles.locationInfo} style={{ width: 80 }}>
          <p className={styles.boldText}>
            {t(countryCodeToName[seller_country.toLocaleUpperCase()])}
          </p>
        </div>

        <div className={styles.icons}>
          <Sedan color={colors[color]} />
          <Heart onClick={handleFavourite} isFavourite={isFav} />
          <Stat onClick={handleStatClick} />
        </div>
      </div>

      <Popup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}>
        <div className={styles.loginPopup}>
          <h3>{t("loginRequired")}</h3>
          <p>{t("loginToAddFavorites")}</p>
          <div className={styles.popupButtons}>
            <Button onClick={() => navigate("/login")}>{t("login")}</Button>
            <Button onClick={() => setShowLoginPopup(false)}>
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Popup>

      <div
        className={styles.containerMobile}
        onClick={() => window.open(shop_url, "_blank")}
      >
        <img
          className={`${styles.carImageMobile} ${
            isLoaded ? styles.loaded : ""
          }`}
          src={imageUrl}
          alt={title}
          onLoad={handleImageLoad}
        />

        <div className={styles.infoMobile}>
          <div>
            <p className={styles.carNameTextMobile}>{title}</p>
            <div className={styles.fullInfoMobile}>
              <p className={styles.smallText}>
                {year} • {mileage} km
              </p>
              <p className={styles.smallText}>
                {t(transmission)} • {displacement}L
              </p>
              <p className={styles.smallText}>
                {date.day} {date.month}
              </p>
              <p className={styles.smallText}>{seller_country}</p>
            </div>
          </div>

          <div className={styles.priceContainerMobile}>
            <p className={styles.priceMobile}>€{price}</p>
            <div className={styles.icons}>
              <Sedan color={colors[color]} />
              <Heart onClick={handleFavourite} isFavourite={isFav} />
              <Stat onClick={handleStatClick} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
