import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { marks, models } from "../model/constants";
import { FilterData, SavedFilter } from "../model/interfaces";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "shared/helper/localstorage";

export const baseFilterData = {
  data: {
    carType: "all",
    isAccidentFree: false,
    isBelowMarket: false,
    isVat: false,
    carBody: [],
    transmission: [],
    radius: null,
    lastAdd: null,
    typeOfSeller: "",
    amountOfOwners: null,
    fuelTypes: "",
    driveTypes: "",
    mileageFrom: "",
    mileageTo: "",
    volumeFrom: "",
    volumeTo: "",
    powerFrom: "",
    powerTo: "",
    yearFrom: "",
    yearTo: "",
    regions: [],
    cities: [],
    priceFrom: "",
    priceTo: "",
    brand_details: [
      { brand: "", models: [], generation: [0], custom_input: "" },
    ],
  },
  sort_metrics: {
    sort_fields: [{ field: "price_eur", order: "asc" }],
  },
};

export function useCarFiltersData(
  isMarket: boolean,
  setSelectedRegions?: Dispatch<SetStateAction<string[]>>
) {
  const [filterData, setFilterData] = useState<FilterData>(() => {
    const filterKey = isMarket ? "marketFilterData" : "offersFilterData";
    return getLocalStorageItem(filterKey, baseFilterData);
  });

  const [hasFilterChanged, setHasFilterChanged] = useState(false);

  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>(() => {
    const savedKey = isMarket ? "marketSavedFilters" : "offersSavedFilters";
    return getLocalStorageItem(savedKey, []);
  });

  const [brandsList] = useState<string[]>(marks);

  const getModelsByBrand = useCallback((brand: string): string[] => {
    if (!brand) return [];
    return (
      models[brand as keyof typeof models].sort((a, b) => a.localeCompare(b)) ||
      []
    );
  }, []);

  useEffect(() => {
    const filterKey = isMarket ? "marketFilterData" : "offersFilterData";
    const cachedFilterData = localStorage.getItem(filterKey);
    if (cachedFilterData) {
      setFilterData(JSON.parse(cachedFilterData));
    } else {
      setFilterData(baseFilterData);
    }
  }, [isMarket]);

  useEffect(() => {
    const filterKey = isMarket ? "marketFilterData" : "offersFilterData";
    setLocalStorageItem(filterKey, filterData);
  }, [filterData, isMarket]);

  const handlers = {
    handleTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, carType: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleCheckboxChange: useCallback(
      (
        checked: boolean,
        field: keyof Pick<
          FilterData["data"],
          "isAccidentFree" | "isBelowMarket" | "isVat"
        >
      ) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: { ...prev.data, [field]: checked },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleBodyTypeChange: useCallback((types: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, carBody: types },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleTransmissionChange: useCallback((types: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, transmission: types },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleRadiusChange: useCallback((radius: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, radius },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleRadiusClear: useCallback((radius: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, radius: null },
        };
        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleLastAddChange: useCallback((lastAdd: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, lastAdd },
        };
        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleLastAddClear: useCallback((lastAdd: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, lastAdd: null },
        };
        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleSellerTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, typeOfSeller: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleSellerTypeClear: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, typeOfSeller: "" },
        };
        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleOwnersChange: useCallback((count: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, amountOfOwners: count },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleOwnersClear: useCallback((count: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, amountOfOwners: null },
        };
        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleFuelTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, fuelTypes: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleFuelTypeClear: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, fuelTypes: "" },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleDriveTypeChange: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, driveTypes: type },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
    handleDriveTypeClear: useCallback((type: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, driveTypes: "" },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleRangeChange: useCallback(
      (field: string, value: number | undefined) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              [field]: value === undefined ? "" : value,
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleRegionsChange: useCallback((regions: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, regions },
        };
        setSelectedRegions && setSelectedRegions(regions);

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleCitiesChange: useCallback((cities: string[]) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: { ...prev.data, cities },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleSortChange: useCallback((field: string, order: "asc" | "desc") => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          sort_metrics: {
            sort_fields: [{ field, order }],
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    resetFilters: useCallback(() => {
      setFilterData(baseFilterData);
      setHasFilterChanged(false);
    }, []),

    saveCurrentFilters: useCallback(
      (filterName: string) => {
        const newFilter: SavedFilter = { name: filterName, data: filterData };
        setSavedFilters((prev) => {
          const updatedFilters = [...prev, newFilter];
          const savedKey = isMarket
            ? "marketSavedFilters"
            : "offersSavedFilters";
          setLocalStorageItem(savedKey, updatedFilters);
          return updatedFilters;
        });
      },
      [filterData, isMarket]
    ),

    loadSavedFilter: useCallback(
      (
        savedFilter: SavedFilter,
        handleCars?: Dispatch<SetStateAction<FilterData | null>>
      ) => {
        setFilterData(savedFilter.data);
        if (handleCars) {
          handleCars(savedFilter.data);
        }
      },
      []
    ),

    deleteSavedFilter: useCallback(
      (filterName: string) => {
        setSavedFilters((prev) => {
          const updatedFilters = prev.filter(
            (filter) => filter.name !== filterName
          );
          const savedKey = isMarket
            ? "marketSavedFilters"
            : "offersSavedFilters";
          setLocalStorageItem(savedKey, updatedFilters);
          return updatedFilters;
        });
      },
      [isMarket]
    ),

    handleBrandChange: useCallback((brand: string, index: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: prev.data.brand_details.map((brandDetail, i) => {
              if (i === index) {
                return {
                  ...brandDetail,
                  brand,
                  models: [],
                  generation: [0],
                  custom_input: "",
                };
              }
              return brandDetail;
            }),
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    handleModelsChange: useCallback(
      (selectedModels: string[], index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    models: selectedModels,
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleGenerationsChange: useCallback(
      (generations: number[], index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    generation: generations,
                    custom_input: "",
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    handleCustomInputChange: useCallback(
      (customInput: string, index: number) => {
        setFilterData((prev) => {
          const newFilterData = {
            ...prev,
            data: {
              ...prev.data,
              brand_details: prev.data.brand_details.map((brandDetail, i) => {
                if (i === index) {
                  return {
                    ...brandDetail,
                    custom_input: customInput,
                  };
                }
                return brandDetail;
              }),
            },
          };

          return newFilterData;
        });
        setHasFilterChanged(true);
      },
      []
    ),

    addBrandDetail: useCallback(() => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: [
              ...prev.data.brand_details,
              { brand: "", models: [], generation: [], custom_input: "" },
            ],
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    removeBrandDetail: useCallback((index: number) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: prev.data.brand_details.filter(
              (_, i) => i !== index
            ),
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),

    clearBrandName: useCallback((brand: string) => {
      setFilterData((prev) => {
        const newFilterData = {
          ...prev,
          data: {
            ...prev.data,
            brand_details: prev.data.brand_details.map((brandDetail, i) => {
              if (brandDetail.brand === brand) {
                return {
                  brand: "",
                  models: [],
                  generation: [],
                  custom_input: "",
                };
              }
              return brandDetail;
            }),
          },
        };

        return newFilterData;
      });
      setHasFilterChanged(true);
    }, []),
  };

  return {
    filterData,
    hasFilterChanged,
    savedFilters,
    brandsList,
    getModelsByBrand,
    handlers,
  };
}
