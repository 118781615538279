import { useEffect, useState } from "react";
import { CarFilters } from "features/СarFilters";
import { CarsList } from "widgets/CarsList/ui/CarsList";
import styles from "./OffersPage.module.css";
import { Cars } from "common/interfaces";
import { getCars } from "features/СarFilters/lib/request";
import { FilterData, SortField } from "features/СarFilters/model/interfaces";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { baseFilterData } from "features/СarFilters/hooks/useCarFiltersData";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function OffersPage() {
  const { t } = useTranslation();
  const mainPageData = localStorage.getItem("mainPageData");
  const cahedCars = mainPageData ? JSON.parse(mainPageData).data.cars : [];
  const [cars, setCars] = useState<Cars[]>(cahedCars);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [filters, setFilters] = useState<FilterData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCars, setTotalCars] = useState<number | null>(null);
  const itemsPerPage = 50;
  const totalPages = totalCars ? Math.ceil(totalCars / itemsPerPage) : 0;

  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price_eur",
    order: "asc",
  });

  const handleSort = async (
    field: SortField["field"],
    order: SortField["order"]
  ) => {
    try {
      setSortLoading(true);
      setCurrentSort({ field, order });
      const cahedFilters = updateFilters();
      // Get current filters or use base filters if none exist
      const returnFilters = !cahedFilters ? baseFilterData : cahedFilters;

      const updatedFilters = {
        ...returnFilters, // Preserve all existing filter data
        data: {
          ...returnFilters.data,
          from_ad: ((currentPage - 1) * itemsPerPage).toString(),
          to_ad: (currentPage * itemsPerPage).toString(),
        },
        sort_metrics: {
          sort_fields: [
            {
              field: field,
              order: order.toLowerCase(),
            },
          ],
        },
      };

      setFilters(updatedFilters);
      const carsList = await getCars(updatedFilters);
      setCars(carsList);
    } catch (error) {
      console.error("Error sorting cars:", error);
      alert("Something went wrong while sorting the cars. Please try again.");
    } finally {
      setSortLoading(false);
    }
  };

  const handleCarsCountChange = (count: number | null) => {
    setTotalCars(count);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleCarsSearch = async (filterData: FilterData | null) => {
    if (!filterData) return;
    try {
      const cahedFilters = updateFilters();
      setLoading(true);

      const carsList = await getCars(cahedFilters);
      setCars(carsList);
    } catch (error) {
      console.error("Error fetching cars:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
    if (filters) {
      const updatedFilters = {
        ...filters,
        data: {
          ...filters.data,
          from_ad: ((newPage - 1) * itemsPerPage).toString(),
          to_ad: (newPage * itemsPerPage).toString(),
        },
      };
      await handleCarsSearch(updatedFilters);
      window.scrollTo({ top: 0 });
    }
  };
  const updateFilters = () => {
    const offersFilterData = localStorage.getItem("offersFilterData");
    const cahedFilters: FilterData | null = offersFilterData
      ? JSON.parse(offersFilterData)
      : null;
    setFilters(cahedFilters);
    return cahedFilters as FilterData;
  };

  useEffect(() => {
    updateFilters();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>CarPass | {t("search")}</title>
      </Helmet>
      <div className={styles.content}>
        <CarFilters
          handleSearch={() => {
            handleCarsSearch(filters);
          }}
          setFilters={setFilters}
          onCarsCountChange={handleCarsCountChange}
          loading={loading || sortLoading}
        />
        <CarsList
          cars={cars}
          onSort={handleSort}
          currentSort={currentSort}
          loading={loading || sortLoading}
        />
        {totalCars !== null && totalCars > 0 && (
          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className={styles.paginationButton}
            >
              <ArrowLeftIcon />
            </button>
            <span className={styles.pageInfo}>
              {currentPage} / {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages || loading}
              className={styles.paginationButton}
            >
              <ArrowRightIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
