import { sendGetRequest } from "../../../shared/api/lib/requests";

// Типизируем ответ, который вы ожидаете от функции (примерная структура)

// Типизируем параметры для getMainPageData
export async function getMainPageData(
  accessToken: string | null = null,
  carsCount: number | null = null
) {
  // Формируем параметры запроса
  const params: Record<string, any> = {};

  // Если accessToken не равен null или undefined, добавляем его в параметры
  if (accessToken) {
    params["access_token"] = localStorage.getItem(accessToken);
  }

  // Если carsCount не равен null или undefined, добавляем его в параметры
  if (carsCount !== null && carsCount !== undefined) {
    params["cars_count"] = carsCount;
  }

  const res = await sendGetRequest("cars-system/main-page-info", params);
  return res;
}
