import React, { useContext, useEffect, useState } from "react";
import { MainPageContext } from "contexts/MainPageContext";
import styles from "./CarShowcase.module.css";
import { SmallCarCard } from "entities/SmallCarCard";
import { SmallCardSkeleton } from "entities/SmallCarCard/ui/SmallCardSkeleton";
import { Button } from "shared/ui/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function CarShowcase() {
  const { t } = useTranslation();
  const { mainPageData, error } = useContext(MainPageContext);
  const [carsFull, setCarsFull] = useState<any>([]);
  const [cars, setCars] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (mainPageData?.data?.cars) {
      setCarsFull(mainPageData.data.cars);
      setCars(mainPageData.data.cars.slice(0, 12));
      setLoading(false);
    }
  }, [mainPageData]);

  if (error) {
    return <div>{error}</div>;
  }

  const renderSkeletons = () => {
    return Array(12)
      .fill(0)
      .map((_, index) => <SmallCardSkeleton key={index} />);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t("mayLike")} 😍</h1>
      <div className={styles.content}>
        {loading ? (
          renderSkeletons()
        ) : (
          <>
            {cars.map((car: any, index: number) => (
              <SmallCarCard car={car} key={index} />
            ))}
            <div className={styles.button}>
              {cars.length < 24 && cars.length !== 0 ? (
                <Button onClick={() => setCars(carsFull.slice(0, 24))}>
                  {t("showMore")}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    navigate("/search");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {t("goToSearch")}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
