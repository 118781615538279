import { Link } from "react-router-dom";
import styles from "./FlyingLogo.module.css";

export function FlyingLogo() {
  return (
    <Link to="/" className={styles.container}>
      <img src="caraiidark.jpg" className={styles.logo} alt="logo" />
    </Link>
  );
}
