import { ChangeUserLoginInfoForm } from "features/ChangeUserLoginInfoForm";
import styles from "./UserAccountPage.module.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function UserAccountPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>CarPass | {t("profile")}</title>
      </Helmet>
      <div className={styles.sizedbox} />

      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.content}>
            <ChangeUserLoginInfoForm />
          </div>
        </div>
      </div>
    </>
  );
}
