import React, { useContext } from "react";
import { MainPageContext } from "contexts/MainPageContext";
import styles from "./ListOfBrands.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "shared/helper/localstorage";
import { baseFilterData } from "features/СarFilters/hooks/useCarFiltersData";

interface BrandData {
  mark: string;
  new_cars_count: number;
  used_cars_count: number;
}

interface ListOfBrandsProps {
  selectedType: string;
}

export const ListOfBrands: React.FC<ListOfBrandsProps> = ({ selectedType }) => {
  const { t } = useTranslation();
  const { mainPageData, loading, error } = useContext(MainPageContext);

  if (loading) {
    return (
      <div className={styles.carList}>
        {Array.from({ length: 24 }).map((_, index) => (
          <div key={index} className={styles.skeletonItem}>
            <div className={styles.skeletonBrand} />
            <div className={styles.skeletonCount} />
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const brands = (mainPageData?.data?.top_brands || []).slice(0, 24);

  if (!brands.length) {
    return <div>{t("noDataToShow")}</div>;
  }

  return (
    <div className={styles.carList}>
      {brands.map((brandData, index) => (
        <Brand key={index} brandData={brandData} selectedType={selectedType} />
      ))}
    </div>
  );
};

interface BrandProps {
  brandData: BrandData;
  selectedType: string;
}

const Brand: React.FC<BrandProps> = ({ brandData, selectedType }) => {
  const { mark, new_cars_count, used_cars_count } = brandData;
  const navigate = useNavigate();

  const model_count =
    selectedType === "all"
      ? new_cars_count + used_cars_count
      : selectedType === "used"
      ? used_cars_count
      : new_cars_count;

  const handleBrandClick = () => {
    const tempBaseFilterData = baseFilterData;
    console.log("tempBaseFilterData", tempBaseFilterData);
    tempBaseFilterData.data.brand_details[0].brand = mark;
    console.log("tempBaseFilterData", tempBaseFilterData);
    setLocalStorageItem("offersFilterData", tempBaseFilterData);
    navigate(`/search?brand=${encodeURIComponent(mark)}`);
  };

  return (
    <div className={styles.carItem} onClick={handleBrandClick}>
      <span className={styles.carBrand}>{mark}</span>
      <span className={styles.carCount}>{model_count}</span>
    </div>
  );
};
