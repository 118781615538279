import styles from "./CarsListHeader.module.css";
import { useTranslation } from "react-i18next";
import { SortField } from "features/СarFilters/model/interfaces";

interface CarsListHeaderProps {
  onSort: (field: SortField["field"], order: SortField["order"]) => void;
  currentSort: { field: SortField["field"]; order: SortField["order"] };
}

export function CarsListHeader({ onSort, currentSort }: CarsListHeaderProps) {
  const { t } = useTranslation();

  const handleSort = (field: SortField["field"]) => {
    const newOrder =
      currentSort.field === field
        ? currentSort.order === "asc"
          ? "desc"
          : currentSort.order === "desc"
          ? "none"
          : "asc"
        : "asc";
    onSort(field, newOrder);
  };

  const getSortIcon = (field: SortField["field"]) => {
    if (currentSort.field !== field || currentSort.order === "none") return "";
    return currentSort.order === "asc" ? (
      <svg
        width="12px"
        height="12px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 4V20M12 4L8 8M12 4L16 8"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ) : (
      <svg
        width="12px"
        height="12px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(180deg)" }}
      >
        <path
          d="M12 4V20M12 4L8 8M12 4L16 8"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <p>{t("photo")}</p>
      </div>

      <div className={styles.column}>
        <p>{t("date")}</p>
      </div>

      <div className={styles.column}>
        <p>{t("car")}</p>
      </div>

      <div className={styles.column}>
        <div
          className={styles.sortableColumn}
          onClick={() => handleSort("year")}
        >
          <p>{t("year")}</p>
          <span>{getSortIcon("year")}</span>
        </div>
      </div>

      <div className={styles.column}>
        <div
          className={styles.sortableColumn}
          onClick={() => handleSort("price_eur")}
        >
          <p>{t("price_eur")}</p>
          <span>{getSortIcon("price_eur")}</span>
        </div>
      </div>

      <div className={styles.column}>
        <div
          className={styles.sortableColumn}
          onClick={() => handleSort("km_age")}
        >
          <p>{t("mileage")}</p>
          <span>{getSortIcon("km_age")}</span>
        </div>
      </div>

      <div className={styles.column}>
        <div
          className={styles.sortableColumn}
          onClick={() => handleSort("displacement")}
        >
          <p>{t("transmissionVolume")}</p>
          <span>{getSortIcon("displacement")}</span>
        </div>
      </div>

      <div className={styles.column}>
        <p>{t("country")}</p>
      </div>

      <div className={styles.column}>
        <p>{t("bodyColor")}</p>
      </div>
    </div>
  );
}
