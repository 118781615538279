import MapChart from "shared/ui/Map/map";
import styles from "./MapStatistics.module.css";

import { MainPageContext } from "contexts/MainPageContext";
import { useContext, useEffect, useState } from "react";

interface Address {
  id: string;
  name: string;
  coordinates: [number, number]; // Replace with the actual structure if different
  // Add more fields based on the structure of `mainPageData.data.adresses.data`
}

interface MapStatisticsProps {
  handleRegions: (regions: string[]) => void; // Function to handle selected regions
  regions: string[]; // List of available regions
  selected: string[]; // List of currently selected regions
}

export function MapStatistics({ handleRegions, selected }: MapStatisticsProps) {
  return (
    <div className={styles.container}>
      <MapChart onChangeCountries={handleRegions} selected={selected} />
    </div>
  );
}
