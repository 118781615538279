import React from "react";
import styles from "./Popup.module.css";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: { x: number; y: number };
}

export function Popup({ isOpen, onClose, children, position }: PopupProps) {
  if (!isOpen) return null;

  const popupStyle = position
    ? ({
        position: "fixed",
        left: position.x,
        top: `calc(${position.y}px * var(--position-multiplier))`,
      } as React.CSSProperties)
    : {};

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={`${styles.popup} ${position ? styles.positioned : ""}`}
        onClick={(e) => e.stopPropagation()}
        style={popupStyle}
      >
        {children}
      </div>
    </div>
  );
}
