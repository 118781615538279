import { CarsList } from "widgets/CarsList/ui/CarsList";
import { MapStatistics } from "widgets/MapStatistics/";
import styles from "./MarketPage.module.css";
import { useEffect, useState, useCallback } from "react";
import { europeanCountriesAndCities } from "../model/constants";
import { Cars } from "common/interfaces";
import { getMarketCars, Metrics } from "features/СarFilters/lib/request";
import { FilterData, SortField } from "features/СarFilters/model/interfaces";
import { CarFilters } from "features/СarFilters";
import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { baseFilterData } from "features/СarFilters/hooks/useCarFiltersData";

export default function MarketPage() {
  const { t } = useTranslation();
  const mainPageData = localStorage.getItem("mainPageData");
  const cahedCars = mainPageData ? JSON.parse(mainPageData).data.cars : [];
  const [cars, setCars] = useState<Cars[]>(cahedCars);
  const [loading, setLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);
  const [filters, setFilters] = useState<FilterData | null>(null);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [currentSort, setCurrentSort] = useState<{
    field: SortField["field"];
    order: SortField["order"];
  }>({
    field: "price_eur",
    order: "asc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCars] = useState<number | null>(null);
  const itemsPerPage = 50; // Количество элементов на странице
  const totalPages = totalCars ? Math.ceil(totalCars / itemsPerPage) : 0;
  const [metrics, setMetrics] = useState<Metrics>();

  const handleSelectRegion = useCallback((selectedValues: string[]) => {
    setSelectedRegions(selectedValues);

    // Update filters with new regions
    setFilters((prevFilters) => {
      if (!prevFilters) return null;

      const updatedFilters: FilterData = {
        ...prevFilters,
        data: {
          ...prevFilters.data,
          regions: selectedValues,
          cities: selectedValues.flatMap(
            (region) => europeanCountriesAndCities[region] || []
          ),
        },
      };

      localStorage.setItem("marketFilterData", JSON.stringify(updatedFilters));
      return updatedFilters;
    });
  }, []);

  const handleCarsSearch = async (filterData: FilterData | null) => {
    if (!filterData) return;

    try {
      const cahedFilters = updateFilters();
      setLoading(true);
      const carsList = await getMarketCars(cahedFilters);
      if (carsList) {
        setCars(carsList.cars);
        setMetrics(carsList.data);
      } else {
        setCars([]);
      }
    } catch (error) {
      console.error("Error fetching market cars:", error);
      setCars([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = async (
    field: SortField["field"],
    order: SortField["order"]
  ) => {
    if (!filters) return;

    try {
      const cahedFilters = updateFilters();
      setSortLoading(true);
      setCurrentSort({ field, order });
      const returnFilters = !cahedFilters ? baseFilterData : cahedFilters;
      const finalFilters = {
        data: {
          ...returnFilters.data,
          from_ad: ((currentPage - 1) * itemsPerPage).toString(),
          to_ad: (currentPage * itemsPerPage).toString(),
        },
        sort_metrics: {
          sort_fields: [
            {
              field: field,
              order: order.toLowerCase(), // ensure order is lowercase
            },
          ],
        },
      };

      setFilters(finalFilters);

      const carsList = await getMarketCars(finalFilters);
      if (carsList) {
        setCars(carsList.cars);
        setMetrics(carsList.data);
      }
    } catch (error) {
      console.error("Error sorting cars:", error);
    } finally {
      setSortLoading(false);
    }
  };
  const handlePageChange = async (newPage: number) => {
    setCurrentPage(newPage);
    if (filters) {
      const updatedFilters = {
        ...filters,
        data: {
          ...filters.data,
          from_ad: ((newPage - 1) * itemsPerPage).toString(),
          to_ad: (newPage * itemsPerPage).toString(),
        },
      };
      handleCarsSearch(updatedFilters);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const updateFilters = () => {
    const marketFilterData = localStorage.getItem("marketFilterData");
    const cahedFilters: FilterData | null = marketFilterData
      ? JSON.parse(marketFilterData)
      : null;
    setFilters(cahedFilters);
    return cahedFilters as FilterData;
  };

  useEffect(() => {
    updateFilters();
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>CarPass | {t("market")}</title>
        <meta
          name="description"
          content="Исследуйте автомобильный рынок с CarPass. Широкий выбор автомобилей, актуальные цены и подробная статистика рынка."
        />
        <meta
          name="keywords"
          content="автомобильный рынок, цены на авто, статистика авторынка, купить автомобиль, CarPass маркет"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="CarPass Маркет - Автомобильный рынок"
        />
        <meta
          property="og:description"
          content="Исследуйте автомобильный рынок с CarPass. Широкий выбор автомобилей, актуальные цены и подробная статистика рынка."
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="CarPass Маркет - Автомобильный рынок"
        />
        <meta
          name="twitter:description"
          content="Исследуйте автомобильный рынок с CarPass. Широкий выбор автомобилей, актуальные цены и подробная статистика рынка."
        />

        {/* Additional SEO tags */}
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://carpass.com/market" />
      </Helmet>
      <CarFilters
        loading={loading}
        handleSearch={() => {
          handleCarsSearch(filters);
        }}
        setFilters={setFilters}
        isMarket={true}
        selectedRegions={selectedRegions}
        metrics={metrics}
        setSelectedRegions={setSelectedRegions}
      />
      <MapStatistics
        selected={selectedRegions}
        handleRegions={handleSelectRegion}
        regions={selectedRegions}
      />
      <CarsList
        cars={cars}
        onSort={handleSort}
        currentSort={currentSort}
        loading={loading || sortLoading}
      />
      {totalCars !== null && totalCars > 0 && (
        <div className={styles.pagination}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1 || loading}
            className={styles.paginationButton}
          >
            <ArrowLeftIcon />
          </button>
          <span className={styles.pageInfo}>
            {currentPage} / {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages || loading}
            className={styles.paginationButton}
          >
            <ArrowRightIcon />
          </button>
        </div>
      )}
    </div>
  );
}
