import { Dispatch, SetStateAction, useEffect } from "react";
import { useCarFiltersData } from "../hooks/useCarFiltersData";
import styles from "./CarFilters.module.css";

import { FilterData } from "../model/interfaces";
import {
  ActionButton,
  BrandDetails,
  CarTypeAndCheckboxes,
  MainFilters,
  SecondaryFilters,
} from "./CarfilterParts";
import { Metrics } from "../lib/request";
import { useTranslation } from "react-i18next";

export interface CarFiltersProps {
  handleSearch: () => void;
  setFilters: Dispatch<SetStateAction<FilterData | null>>;
  isMarket?: boolean;
  selectedRegions?: string[];
  onCarsCountChange?: (count: number | null) => void;
  metrics?: Metrics;
  loading?: boolean;
  setSelectedRegions?: Dispatch<SetStateAction<string[]>>;
}

export default function CarFilters({
  setFilters,
  isMarket = false,
  selectedRegions = [],
  onCarsCountChange,
  loading,
  metrics,
  handleSearch,
  setSelectedRegions,
}: CarFiltersProps) {
  const { t } = useTranslation();
  const { filterData, hasFilterChanged, savedFilters, handlers } =
    useCarFiltersData(isMarket, setSelectedRegions);

  useEffect(() => {
    if (selectedRegions.length > 0) {
      handlers.handleRegionsChange(selectedRegions);
    }
  }, [selectedRegions, handlers]);

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        {/* Car Type and Checkboxes */}

        <CarTypeAndCheckboxes filterData={filterData} handlers={handlers} />
        {/* Brand Details Section */}
        <BrandDetails
          filterData={filterData}
          handlers={handlers}
          isMarket={isMarket}
        />

        <div style={{ margin: "12px 0", borderTop: "2px dotted #e6e6e6" }} />
        {/* main filters */}
        <MainFilters filterData={filterData} handlers={handlers} />
        <div style={{ margin: "12px 0", borderTop: "2px dotted #e6e6e6" }} />
        {/* secondary filters */}
        <SecondaryFilters filterData={filterData} handlers={handlers} />

        {/* Action Buttons */}
        <ActionButton
          loading={loading}
          handleSearch={handleSearch}
          filterData={filterData}
          handlers={handlers}
          isMarket={isMarket}
          setFilters={setFilters}
          hasFilterChanged={hasFilterChanged}
          savedFilters={savedFilters}
          onCarsCountChange={onCarsCountChange}
        />
        <div style={{ margin: "12px 0", borderTop: "2px dotted #e6e6e6" }} />
        {isMarket ? (
          <ul className={styles.metricsList}>
            <li>
              <p className={styles.mertricsTitle}>{t("quantity")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>
                  {metrics.cars_count} {t("units")}
                </p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
            <li>
              <p className={styles.mertricsTitle}>{t("removedLast3Months")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>
                  {metrics.deactivated_cars_last_3_months_count} {t("units")}
                </p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
            <li>
              <p className={styles.mertricsTitle}>{t("avgPrice")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>
                  {metrics.average_selling_price}€
                </p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
            <li>
              <p className={styles.mertricsTitle}>{t("avgSalePeriod")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>
                  {metrics.average_time_on_market} {t("days")}
                </p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
            <li>
              <p className={styles.mertricsTitle}>{t("liquidity")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>{metrics.liquidity}%</p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
            <li>
              <p className={styles.mertricsTitle}>{t("averageMileage")}</p>
              {loading ? (
                <div className={styles.loader}></div>
              ) : metrics ? (
                <p className={styles.mertricsDesc}>
                  {metrics.average_mileage_at_selling} {t("km")}
                </p>
              ) : (
                <p className={styles.mertricsDesc}></p>
              )}
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
}
