import { MainPageProvider } from "contexts/MainPageContext";
import { AutoNameSelector } from "widgets/AutoNameSelector";
import { CarShowcase } from "widgets/CarShowcase";
import styles from "./MainPage.module.css";
import useAddressStore from "store/useAdressStore";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export function MainPage() {
  const { addresses, fetchAddresses, loadFromCache } = useAddressStore();
  const { t } = useTranslation();
  useEffect(() => {
    loadFromCache();
    if (!addresses) {
      fetchAddresses();
    }
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>CarPass | {t("home")}</title>
        <meta
          property="og:image"
          content="https://caraii.com/previewmain.png"
        />
      </Helmet>
      <AutoNameSelector />
      <CarShowcase />
    </div>
  );
}
