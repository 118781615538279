import styles from "./CarsList.module.css";
import { CarCard } from "../../../entities/CarCard/ui/CarCard";
import { CardSkeleton } from "../../../entities/CarCard/ui/CardSkeleton";
import { parseDateTime } from "../lib/ParseDateTime";
import { useEffect } from "react";
import { Cars } from "../../../common/interfaces";
import { useFavoriteContext } from "../../../contexts/FavoriteContext";
import { getFavouriteData } from "widgets/navbar/lib/request";
import { useTranslation } from "react-i18next";
import { CarsListHeader } from "./CarListHeader";
import { SortField } from "features/СarFilters/model/interfaces";

interface CarsListProps {
  cars?: Cars[];
  onSort: (field: SortField["field"], order: SortField["order"]) => void;
  currentSort: { field: SortField["field"]; order: SortField["order"] };
  loading?: boolean;
}

export function CarsList({
  cars,
  onSort,
  currentSort,
  loading = false,
}: CarsListProps) {
  const { t } = useTranslation();
  const { favoriteList, setFavoriteList } = useFavoriteContext();

  useEffect(() => {
    const areFavoritesEqual = (
      newFavorites: any[],
      currentFavorites: any[]
    ): boolean => {
      return JSON.stringify(newFavorites) === JSON.stringify(currentFavorites);
    };

    async function fetchFavorites() {
      const response = await getFavouriteData();
      const newFavorites = response || [];

      if (!areFavoritesEqual(newFavorites, favoriteList)) {
        setFavoriteList(newFavorites);
      }
    }

    fetchFavorites();
  }, [favoriteList, setFavoriteList]);

  const isFavoriteListArray = Array.isArray(favoriteList);

  const renderSkeletons = () => {
    return Array(50)
      .fill(0)
      .map((_, index) => <CardSkeleton key={index} />);
  };

  return (
    <div className={styles.layout}>
      <CarsListHeader onSort={onSort} currentSort={currentSort} />
      {loading ? (
        renderSkeletons()
      ) : !cars || !Array.isArray(cars) || cars.length === 0 ? (
        <div className={styles.header_info}>
          <h1>{t("noCars")}</h1>
        </div>
      ) : (
        cars.map((car) => {
          const isFavorite =
            isFavoriteListArray &&
            favoriteList.some((fav: any) => fav.inner_id === car.inner_id);
          return (
            <CarCard
              seller_country={car.seller_country}
              key={car.inner_id}
              title={car.title}
              inner_id={car.inner_id}
              date={parseDateTime(car.date_added)}
              color={car.color}
              displacement={car.displacement}
              transmission={car.transmission}
              price={car.price_eur}
              mileage={car.km_age}
              car_picture={car.image_urls}
              year={car.year}
              shop_url={car.url}
              isFavorite={isFavorite}
            />
          );
        })
      )}
    </div>
  );
}
