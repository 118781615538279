import { FlyingLogo } from "shared/ui/FlyingLogo";
import { RegistrationSteps } from "widgets/RegistrationSteps";
import styles from "./RegistrationPage.module.css";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export function RegistrationPage() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <Helmet>
        <title>CarPass | {t("registration")}</title>
      </Helmet>
      <FlyingLogo />
      <div className={styles.content}>
        <RegistrationSteps />
      </div>
    </div>
  );
}
