import { useTranslation } from "react-i18next";
import styles from "../CarFilters.module.css";
import { OrangeArrowDown } from "shared/ui/Icons/OrangeArrowDown";
import { Button } from "shared/ui/button";
import {
  CarFilterPart,
  FilterData,
  SavedFilter,
} from "features/СarFilters/model/interfaces";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getCarsCount } from "features/СarFilters/lib/request";
import { useOutsideClick } from "shared/ui/Selector/hooks/useOutsideClick";

interface IActionButton extends CarFilterPart {
  isMarket: boolean;
  setFilters: Dispatch<SetStateAction<FilterData | null>>;
  hasFilterChanged: boolean;
  onCarsCountChange?: (count: number | null) => void;
  savedFilters: SavedFilter[];
  handleSearch: () => void;
  loading?: boolean;
}

const SavedFiltersDropdown = ({
  savedFilters,
  handlers,
  setFilters,
  setIsSavedFiltersOpen,
}: {
  savedFilters: SavedFilter[];
  handlers: any;
  setFilters: Dispatch<SetStateAction<FilterData | null>>;
  setIsSavedFiltersOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={styles.savedFiltersDropdown}>
      {savedFilters.map((filter, index) => (
        <div key={index} className={styles.savedFilterItem}>
          <span
            onClick={async () => {
              setIsLoading(true);
              try {
                await handlers.loadSavedFilter(filter, setFilters);
              } finally {
                setIsLoading(false);
                setIsSavedFiltersOpen(false);
              }
            }}
            className={`${styles.savedFilterName} ${
              isLoading ? styles.loading : ""
            }`}
          >
            {filter.name}
          </span>
          <button
            onClick={() => {
              handlers.deleteSavedFilter(filter.name);
              setIsSavedFiltersOpen(false);
            }}
            className={styles.deleteFilterButton}
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};

const ActionButton = ({
  handlers,
  setFilters,
  filterData,
  hasFilterChanged,
  onCarsCountChange,
  isMarket,
  savedFilters,
  handleSearch,
  loading,
}: IActionButton) => {
  const { t } = useTranslation();
  const [isShowingSaveDialog, setIsShowingSaveDialog] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");
  const [isSavedFiltersOpen, setIsSavedFiltersOpen] = useState(false);
  const [carsCount, setCarsCount] = useState<number | null>(null);
  const [isCountLoading, setIsCountLoading] = useState(false);
  const [isCarsLoading, setIsCarsLoading] = useState(false);

  const saveFilterRef = useRef<HTMLDivElement>(null);

  const handleSaveFilter = () => {
    if (!newFilterName.trim()) return;
    handlers.saveCurrentFilters(newFilterName.trim());
    setNewFilterName("");
    setIsShowingSaveDialog(false);
  };

  const handleSearchWrapper = async () => {
    if (isCarsLoading) return;

    setIsCarsLoading(true);

    try {
      await handleSearch();
    } catch (error) {
    } finally {
      setIsCarsLoading(false);
    }
  };

  const updateCarsCount = useCallback(async () => {
    setIsCountLoading(true);
    try {
      const response = await getCarsCount(filterData);
      setCarsCount(response.count);
      onCarsCountChange?.(response.count);
    } catch (error) {
      console.error("Error fetching cars count:", error);
      setCarsCount(null);
      onCarsCountChange?.(null);
    } finally {
      setIsCountLoading(false);
    }
  }, [filterData, hasFilterChanged]); // Убедитесь, что тут нет лишних зависимостей

  useEffect(() => {
    if (!isMarket && hasFilterChanged) {
      const timeout = setTimeout(() => {
        updateCarsCount();
      }, 1000); // Debounce
      return () => clearTimeout(timeout);
    }
  }, [hasFilterChanged, isMarket, updateCarsCount, filterData]);
  useEffect(() => {
    updateCarsCount();
  }, []);

  useOutsideClick(saveFilterRef, () => setIsShowingSaveDialog(false));
  const returnButtonContent = () => {
    if (isCarsLoading || loading) {
      return <div className={styles.loader}></div>;
    }
    if (isCountLoading || loading) {
      return <div className={styles.loader}></div>;
    }
    if (isMarket) {
      return t("show");
    }
    if (carsCount === null) {
      return `${t("+100000")} ${t("cars")}`;
    }
    if (carsCount === 0) {
      return t("noCars");
    }
    return `${t("show")} ${carsCount} ${t("cars")}`;
  };
  return (
    <div className={styles.row}>
      <div className={styles.bottomRow}>
        <button onClick={handlers.resetFilters} className={styles.button}>
          {t("reset")}
        </button>
      </div>

      <div className={styles.bottomRow} style={{ position: "relative" }}>
        <button
          onClick={() => setIsSavedFiltersOpen((prev) => !prev)}
          className={styles.button}
        >
          {t("saveFilters")} <OrangeArrowDown />
        </button>

        {isSavedFiltersOpen && (
          <div className={styles.saveDialog} ref={saveFilterRef}>
            <div className={styles.saveNewFilter}>
              <input
                type="text"
                value={newFilterName}
                onChange={(e) => setNewFilterName(e.target.value)}
                placeholder={t("enterFilterName")}
                className={styles.filterNameInput}
              />
              <button
                onClick={handleSaveFilter}
                className={styles.saveButton}
                disabled={!newFilterName.trim()}
              >
                {t("save")}
              </button>
              {savedFilters.length > 0 && (
                <SavedFiltersDropdown
                  savedFilters={savedFilters}
                  handlers={handlers}
                  setFilters={setFilters}
                  setIsSavedFiltersOpen={setIsSavedFiltersOpen}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className={styles.bottomRow}>
        <Button onClick={handleSearchWrapper} className={styles.btn}>
          {returnButtonContent()}
        </Button>
      </div>
    </div>
  );
};

export default ActionButton;
